import React from "react";
import { Form, Button, Typography, Space, message } from "antd";
import KeywordBlock from './KeywordBlock';
import { fetchOrder, convertKeywords, convertKeywordsClear } from "../../actions/ordersActions";
import { connect } from "react-redux";

const { Title } = Typography;

class GoogleKeywordConversionForm extends React.Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    keywordBlocks: [],
    existingKeywords: [],
    platformType: '',
    isFormValid: false,
    submissionType: 'submit',
  };

  componentDidUpdate(prevProps, _prevState) {
    const { order } = this.props;

    if (prevProps.convertKeywordSuccess !== this.props.convertKeywordSuccess && this.props.convertKeywordSuccess) {
      this.props.dispatch(convertKeywordsClear());
      message.success(`Keywords converted successfully`);
      this.props.dispatch(fetchOrder(order.id));
      this.props.handleGoogleKeywordsConversionSuccess(order)
    }
  }

  componentDidMount() {
    const { order } = this.props;
    const platformType = order.resource.payload.platform_type.toLowerCase();
    const existingKeywords = order.resource.keyword_blocks['google'].flat().map(kw => `${kw.keyword_phrase} + ${kw.tag_phrase}`);

    let keywordBlocks = existingKeywords.map(() => ({}));

    if (order && order.resource && order.resource.converted_keywords_payload && order.resource.converted_keywords_payload.length > 0) {
      const keywords = order.resource.converted_keywords_payload;
      keywordBlocks = keywords.map((platformKeyword, index) => {
        const platform = Object.keys(platformKeyword)[0];
        const platformKeywords = platformKeyword[platform].map((keyword, index) => {
          return { keyword_phrase: keyword.keyword_phrase, tag_phrase: keyword.tag_phrase };
        });

        const blockCount = platform === "Bing" ? 7 : 3;
        const emptyBlocks = blockCount - platformKeywords.length;
        for (let i = 0; i < emptyBlocks; i++) {
          platformKeywords.push({ keyword_phrase: "", tag_phrase: "" });
        }

        return { [platform]: platformKeywords };
      });
    }

    this.setState({
      platformType: platformType,
      existingKeywords: existingKeywords,
      keywordBlocks: keywordBlocks,
    }, () => {
      const initialValues = keywordBlocks; 
      this.formRef.current.setFieldsValue({ keywordBlocks: initialValues });
    });
  }

  addBlock = (keywordIndex, platform) => {
    this.setState((prevState) => {
      const keywordBlocks = [...prevState.keywordBlocks];

      const blockCount = platform === "Bing" ? 7 : 3;
      const blocks = Array.from({ length: blockCount }, () => ({ keyword_phrase: "", tag_phrase: "" }));

      if ((platform === "Bing" && prevState.platformType !== 'google + bing') || (platform === "YouTube" && prevState.platformType !== 'google + youtube')) {
          blocks[0].keyword_phrase = prevState.existingKeywords[keywordIndex]?.split(' + ')[0] || "";
          blocks[0].tag_phrase = prevState.existingKeywords[keywordIndex]?.split(' + ')[1] || "";
      }

      if (!keywordBlocks[keywordIndex]) {
          keywordBlocks[keywordIndex] = {};
      }
      keywordBlocks[keywordIndex][platform] = blocks;

      return { keywordBlocks };
    }, () => {
        const initialValues = this.state.keywordBlocks; 
        this.formRef.current.setFieldsValue({ keywordBlocks: initialValues });
        this.updateFormValidity();
    })
  };


  handleInputChange = (keywordIndex, blockIndex, platform, field, value) => {
    this.setState((prevState) => {
      const keywordBlocks = [...prevState.keywordBlocks];
      const block = keywordBlocks[keywordIndex][platform][blockIndex];
      block[field] = value;
      return { keywordBlocks };
    }, this.updateFormValidity);
  };

  changePlatform = (keywordIndex) => {
    this.setState((prevState) => {
      const keywordBlocks = [...prevState.keywordBlocks];
      const currentBlock = keywordBlocks[keywordIndex];
      const currentPlatform = Object.keys(currentBlock)[0];
      const newPlatform = currentPlatform === "Bing" ? "YouTube" : "Bing";

      const blockCount = newPlatform === "Bing" ? 7 : 3;
      if (!currentBlock[newPlatform]) {
        currentBlock[newPlatform] = currentBlock[currentPlatform].slice(0, blockCount).map(({ keyword_phrase, tag_phrase }) => ({
          keyword_phrase: keyword_phrase,
          tag_phrase: tag_phrase
        }));

        if (currentBlock[currentPlatform].length < blockCount) {
          currentBlock[newPlatform] = [
            ...currentBlock[newPlatform],
            ...Array.from({ length: blockCount - currentBlock[currentPlatform].length }, () => ({ keyword_phrase: "", tag_phrase: "" }))
          ];
        }
      }

      keywordBlocks[keywordIndex] = { [newPlatform]: currentBlock[newPlatform] };

      return { keywordBlocks };
    }, () => {
      const initialValues = this.state.keywordBlocks;
      this.formRef.current.setFieldsValue({ keywordBlocks: initialValues });
      this.updateFormValidity();
    });
  };

  removeBlock = (keywordIndex) => {
    this.setState((prevState) => {
      const keywordBlocks = [...prevState.keywordBlocks];
      keywordBlocks[keywordIndex] = {};
      return { keywordBlocks };
    }, () => {
      const initialValues = this.state.keywordBlocks;
      this.formRef.current.setFieldsValue({ keywordBlocks: initialValues });
      this.updateFormValidity();
    });
  };

  updateFormValidity = () => {
    const isFormValid = this.state.keywordBlocks.every(block => {
      return Object.values(block).some(platformBlocks => {
        return Array.isArray(platformBlocks) && platformBlocks.some(({ keyword_phrase, tag_phrase }) => {
          return keyword_phrase?.trim() !== '' && tag_phrase?.trim() !== '';
        });
      });
    });

    this.setState({ isFormValid });
  };

  handleSubmissionType = (type) => {
    this.setState({ submissionType: type }, () => {
      this.formRef.current.validateFields()
        .then(() => {
          this.formRef.current.submit();
        })
        .catch(error => {
          console.log('Validation failed:', error);
        });
    });
  };

  handleSubmit = (submissionType) => {
    const { order } = this.props;

    const filteredKeywordBlocks = this.state.keywordBlocks.map(block => {
        if (block === undefined) return {};

        const platforms = Object.keys(block);
        if (platforms.length === 0) {
            return {};
        }
        return platforms.reduce((acc, platform) => {
            const filteredBlocks = block[platform].filter(({ keyword_phrase, tag_phrase }) => 
                keyword_phrase.trim() !== '' || tag_phrase.trim() !== ''
            );
            if (filteredBlocks.length > 0) {
                acc[platform] = filteredBlocks;
            }
            return acc;
        }, {});
    }).filter(block => Object.keys(block).length > 0);

    const isSubmitted = submissionType === 'submit' ? true : false

    this.props.dispatch(convertKeywords(order.id, filteredKeywordBlocks, isSubmitted));
  };

  isKeywordCombinationValid = (keywordIndex, blockIndex, platform, keyword_phrase, tag_phrase) => {
    const kwCombination = `${keyword_phrase} + ${tag_phrase}`;
    const isFirstEntry = blockIndex === 0;
    const isGoogleKW = this.state.existingKeywords[keywordIndex] === kwCombination;
    if (isFirstEntry && isGoogleKW && ((platform === "Bing" && this.state.platformType !== 'google + bing') || (platform === "YouTube" && this.state.platformType !== 'google + youtube'))) {
        return { isValid: true, message: '' };
    }

    if (this.state.existingKeywords.includes(kwCombination) && (keyword_phrase.trim() !== '' || tag_phrase.trim() !== '')) {
        return { isValid: false, message: 'This keyword + tag phrase is already covered in the order' };
    }
    return { isValid: true, message: '' };
  };

  phraseRowIsValid = (keyword_phrase, tag_phrase) => {
    return (
      (keyword_phrase?.trim() !== '' && tag_phrase?.trim() !== '') ||
      (keyword_phrase?.trim() === '' && tag_phrase?.trim() === '')
    );
  };

  render() {
    const { order } = this.props;
    const keywords = order.resource.keyword_blocks['google'];
    const { keywordBlocks, isFormValid } = this.state;

    return (
      <>
        <Title level={2}>Google to Bing / YouTube Conversion</Title>
        <Title level={4}>You have {keywords.length} Google KWs</Title>

        <Form ref={this.formRef} initialValues={keywordBlocks}>
          {keywords.flat().map((keyword, index) => (
            <KeywordBlock
              key={index}
              keyword={keyword}
              keywordIndex={index}
              keywordBlocks={keywordBlocks}
              platformType={this.state.platformType}
              addBlock={this.addBlock}
              removeBlock={this.removeBlock}
              handleInputChange={this.handleInputChange}
              changePlatform={this.changePlatform}
              isKeywordCombinationValid={this.isKeywordCombinationValid}
            />
          ))}
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            {keywordBlocks.length > 0 && (
              <Space direction='horizontal'>
                <Button type="primary" onClick={() => this.handleSubmit('submit')} disabled={!isFormValid}>
                    Submit Order
                </Button>
                <Button onClick={() => this.handleSubmit('draft')} disabled={!isFormValid}>
                    Save Draft
                </Button>
              </Space>
            )}
          </div>
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.orders.loading,
  error: state.orders.error,
  convertKeywordSuccess: state.orders.convertKeywordSuccess,
});

export default connect(mapStateToProps)(GoogleKeywordConversionForm);

