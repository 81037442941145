import { Card, Col, Row, Skeleton, Tabs, Button, message } from "antd";
import React from "react"

import { connect } from "react-redux";
import { fetchQuote, fetchQuoteClear, transferVendorQuote } from "../../actions/quotesActions";
import { BASE_QUOTE_REQUEST_ROUTES } from "../../util/routes";
import QuoteInfo from "./QuoteInfo";
import PageHeader from '../shared/PageHeader';
import EditQuote from "./EditQuote";
import Messages from "../shared/Messages";

const { TabPane } = Tabs;
const WEBSITE_DESIGN_PLATINUM_PARTNER_TB_ID = process.env.REACT_APP_WEBSITE_DESIGN_PLATINUM_PARTNER_TB_ID;

class QuoteDetails extends React.Component {

  handleTransferVendor = () => {
    const { quote } = this.props;
    this.props.dispatch(transferVendorQuote(quote.id))
      .then(() => {
        this.props.history.push('/quotes');
        message.success(`Vendor quote transferred successfully`);
      })
      .catch(error => {
        message.error('Error transferring vendor quote:', error);
      });
  };

  componentDidMount() {
    this.props.dispatch(fetchQuoteClear());
    const { id } = this.props.match.params;
    this.props.dispatch(fetchQuote(id));
  }

  render() {
    console.log('id is', WEBSITE_DESIGN_PLATINUM_PARTNER_TB_ID)
    const { quote, mobileView, user, vendorQuoteTransferLoading } = this.props;
    const loading = (quote == null || quote === undefined);
    const defaultActiveKey = this.props.location.search.includes("conversation") ? "conversation" : "quotes"
    if (loading) {
      return (
        <div className="content-holder" style={{ background: '#fff' }}>
          <Skeleton />
        </div>
      )
    }
    else {
      const title = quote.number;
      const routes = BASE_QUOTE_REQUEST_ROUTES.concat([{
        path: `/quotes/${quote.id}`,
        breadcrumbName: `${quote.number}`,
      }])
      return (
        <>
          <PageHeader title={title} routes={routes} />
          <Row className="content-holder quote-details" style={{ background: '#fff' }}>
            {!mobileView &&
              <Col sm={9} xs={24}>
                <QuoteInfo quote={quote} />
              </Col>
            }
            <Col sm={{ span: 14, offset: 1 }} xs={24}>
              <Tabs defaultActiveKey={defaultActiveKey} animated={false}>
                {mobileView &&
                  <TabPane tab="Quote Details" key="info">
                    <QuoteInfo quote={quote} />
                  </TabPane>
                }
                <TabPane tab="Quote information" key="quotes">
                  <Card title="Line items">
                    <EditQuote quote={quote} />
                  </Card>
                </TabPane>
                <TabPane tab="Conversation" key="conversation">
                  <Card>
                    <Messages container={quote} mobileView={mobileView} container_type='VendorQuote' />
                  </Card>
                </TabPane>
              </Tabs>
              { user.vendor_id == WEBSITE_DESIGN_PLATINUM_PARTNER_TB_ID &&
                  <Button
                  htmlType="submit"
                  type="primary"
                  style={{ position: 'absolute', top: 0, right: 0, margin: '10x' }}
                  loading={vendorQuoteTransferLoading}
                  onClick={this.handleTransferVendor}
                  >
                    Send to Adaan
                  </Button>
              }
            </Col>
          </Row>
        </>
      )
    }
  }
}

const mapStateToProps = state => ({
  user: state.profile.profile,
  quote: state.quotes.quote,
  loading: state.quotes.loading,
  error: state.quotes.error,
  vendorQuoteTransferLoading: state.quotes.vendorQuoteTransferLoading
});

export default connect(mapStateToProps)(QuoteDetails);