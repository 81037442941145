import {
  FETCH_PROFILE_BEGIN,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  UPDATE_PROFILE_BEGIN,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_CLEAR,
  DECREMENT_UNREAD_QUOTE_MESSAGES_COUNT,
  DECREMENT_UNREAD_ORDER_MESSAGES_COUNT,
  DECREMENT_UNREAD_CONVERSATION_MESSAGES_COUNT,
  INCREMENT_UNREAD_QUOTE_MESSAGES_COUNT,
  INCREMENT_UNREAD_ORDER_MESSAGES_COUNT,
  INCREMENT_UNREAD_CONVERSATION_MESSAGES_COUNT
} from '../actions/profileActions';

const initialState = {
  profile: null,
  loading: false,
  error: null,
  unreadQuoteMessages: 0,
  unreadOrderMessages: 0,
  unreadConversationMessages: 0,
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROFILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.profile,
        unreadQuoteMessages: action.payload.profile.unread_quote_messages_count,
        unreadOrderMessages: action.payload.profile.unread_order_messages_count,
        unreadConversationMessages: action.payload.profile.unread_conversation_messages_count
      };

    case FETCH_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        profile: null
      };

    case UPDATE_PROFILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.profile,
        success: true,
      };

    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_PROFILE_CLEAR:
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
      };

    case DECREMENT_UNREAD_QUOTE_MESSAGES_COUNT:
      return {
        ...state,
        unreadQuoteMessages: action.payload.unreadQuoteMessagesCount - 1
      };

    case DECREMENT_UNREAD_ORDER_MESSAGES_COUNT:
      return {
        ...state,
        unreadOrderMessages: action.payload.unreadOrderMessagesCount - 1
      };

    case DECREMENT_UNREAD_CONVERSATION_MESSAGES_COUNT:
      return {
        ...state,
        unreadConversationMessages: action.payload.unreadConversationMessagesCount - 1
      };

    case INCREMENT_UNREAD_QUOTE_MESSAGES_COUNT:
      return {
        ...state,
        unreadQuoteMessages: action.payload.unreadQuoteMessagesCount + 1
      };

    case INCREMENT_UNREAD_ORDER_MESSAGES_COUNT:
      return {
        ...state,
        unreadOrderMessages: action.payload.unreadOrderMessagesCount + 1
      };

    case INCREMENT_UNREAD_CONVERSATION_MESSAGES_COUNT:
      return {
        ...state,
        unreadConversationMessages: action.payload.unreadConversationMessagesCount + 1
      };

    default:
      return state;
  }
}