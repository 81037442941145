import {
  UPDATE_ARTICLE_BEGIN,
  UPDATE_ARTICLE_FAILURE,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_CLEAR,
} from '../actions/prArticleActions';

const initialState = {
  error: null,
  loading: false,
  article: null,
  success: null,
};

export default function prArticleReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ARTICLE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case UPDATE_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        article: action.payload.article,
        success: true,
      };

    case UPDATE_ARTICLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: false,
      };

    case UPDATE_ARTICLE_CLEAR:
      return {
        ...state,
        loading: false,
        article: null,
        error: null,
        success: null,
      };

    default:
      return state;
  }
}