import _ from 'lodash';
import React, { useEffect, useState } from "react"
import { Descriptions, Space, Modal, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons"
import styled from 'styled-components';

const DownloadLink = styled.a`
  position: absolute;
  right: 56px;
`

const PrArticle = ({ resource }) => {
  const [openImageModal, setOpenImageModal] = useState(false)
  const [activeImageUrl, setActiveImageUrl] = useState(null)
  const [previewTitle, setPreviewTitle] = useState(null)

  const handleImageClick = (image) => {
    setOpenImageModal(true);
    setActiveImageUrl(image.url)
    setPreviewTitle(image.name)
  }

  const handleCancel = () => {
    setOpenImageModal(false);
    setActiveImageUrl(null)
    setPreviewTitle(null)
  }

  return (
    <>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Contact">{resource.contact_name}</Descriptions.Item>
        <Descriptions.Item label="Age Group">{resource.min_age} - {resource.max_age}</Descriptions.Item>
        <Descriptions.Item label="Gender">{resource.gender}</Descriptions.Item>
        <Descriptions.Item label="Zip code">{resource.zip_code}</Descriptions.Item>
        <Descriptions.Item label="Package">{resource.package_information}</Descriptions.Item>
        <Descriptions.Item label="Interests">{resource.interests}</Descriptions.Item>
        <Descriptions.Item label="Additional Info">{resource.additional_information}</Descriptions.Item>
        <Descriptions.Item label="Suggested title">{resource.suggested_title}</Descriptions.Item>
        <Descriptions.Item label="Author name">{resource.author_name}</Descriptions.Item>
        <Descriptions.Item label="Facebook teaser">{resource.facebook_teaser}</Descriptions.Item>
        <Descriptions.Item label={`Member uploaded ${resource.content_attachment_type}`}>
          <a target="_blank" href={resource.content_attachment.url}>{resource.content_attachment.name}</a>
        </Descriptions.Item>
        <Descriptions.Item label="Images">
          <Space direction='horizontal'>
            {resource.images.map((image) => <img onClick={() => handleImageClick(image)} src={image.thumbnail_url} width='60' height='60' />)}
          </Space>
        </Descriptions.Item>
      </Descriptions>
      <Modal
        visible={openImageModal}
        title={
          <>
            {previewTitle} <DownloadLink href={activeImageUrl} download={previewTitle} target="_blank" ><DownloadOutlined style={{ color: "#8d8d8d" }} /></DownloadLink>
          </>
        }
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={activeImageUrl} />
      </Modal>
    </>
  )
}

export default PrArticle;