import React from "react"
import moment from 'moment'
import PropTypes from "prop-types"
import { Table, Popconfirm, Button, Space } from 'antd';
import StatusTag from "../shared/StatusTag";
import { humanizeText } from "../../util/text";
import { Link } from "react-router-dom";
import { ProductsWithETA } from "../../util/productConstants";

class List extends React.Component {

  state = {
    data: this.props.orders,
    pagination: {
      current: 1,
      showSizeChanger: true,
      total: this.props.totalOrders,
      defaultPageSize: this.props.pageSize || 25,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
    });
    this.props.onTableChange(pagination, filters, sorter);
  };

  getTableAction = record => {
    return record.state === 'active' ? 'View' : 'Edit';
  }

  generateActions = record => {
    let actions = []
    actions.push(<Link to={`/orders/${record.id}`}>{this.getTableAction(record)}</Link>)

    if(ProductsWithETA.includes(record.kind) && record.state === 'open') {
      actions.push(
        <Popconfirm
          title="Are you sure you want to mark this project as complete?"
          onConfirm={() => this.props.markAsComplete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link">Mark as complete</Button>
        </Popconfirm>
      )      
    }
    return actions;
  }

  timeformat(datetime, record) {
    const date = moment(datetime).format('MM/DD/YYYY');

    if (!['answer_bot_agent_setup', 'answer_bot_knowledge_file_upload', 'answer_bot_phone_setup'].includes(record.kind)) {
      return date;
    }

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const formattedTime = new Date(datetime).toLocaleString('en-US', {
      timeZone,
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    return `${date} ${formattedTime}`;
  }

  stringSorter = (key) => (a, b) => (a[key] || '').localeCompare(b[key] || '');

  render() {
    const { loading, orders } = this.props;
    let columns = [
      {
        title: 'Order Number',
        dataIndex: 'number',
        sorter: true,
      },
      {
        title: 'Kind',
        dataIndex: 'kind',
        render: (kind, record) => kind === 'pr_article' ? 'PR article' : humanizeText(kind),
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        filterMultiple: false,
        render: (status, record) => (
          <StatusTag status={record.state} />
        ),
      },
      {
        title: 'Submitted at',
        dataIndex: 'submitted_at',
        render: (datetime,record) => this.timeformat(datetime,record),
        sorter: true,
      },
      {
        title: 'Client',
        dataIndex: 'client',
        sorter: this.stringSorter('client'),
      },
      {
        title: 'Member',
        dataIndex: 'member',
        sorter: this.stringSorter('member'),
      },
      {
        title: 'Action',
        mobile: true,
        dataIndex: 'id',
        render: (id, record) => <Space size="middle">{this.generateActions(record)}</Space>,
        width: '220px'
      },
    ]

    const { pagination } = this.state;
    pagination.total = this.props.totalOrders;
    pagination.current = this.props.page;

    return (
      <>
        <Table
          columns={columns}
          rowKey={record => record.id}
          dataSource={orders}
          pagination={pagination}
          loading={loading}
          rowClassName={(record) => record.unread ? 'data-row active-row' : 'data-row'}
          onChange={this.handleTableChange}
          size="middle"
        />
      </>
    )
  }
}

List.propTypes = {
  totalOrders: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  orders: PropTypes.array.isRequired,
  onTableChange: PropTypes.func.isRequired,
};

export default List;
