import React from "react";
import moment from "moment";
import { upperFirst } from "lodash";
import { Card, Descriptions, Button, Typography, Space, Tag } from "antd";
import StatusTag from "../shared/StatusTag";
const { Title } = Typography;

class QuoteInfo extends React.Component {
  render() {
    const {
      quote,
      quote: { payload },
    } = this.props;

    const generateElement = (key, value) => {
      return (
        <Descriptions.Item label={upperFirst(key.split("_").join(" "))}>
          {value}
        </Descriptions.Item>
      );
    };

    function generateData(data) {
      const newData = Object.keys(data).reduce((result, currentKey) => {
        if (
          typeof data[currentKey] === "string" ||
          typeof data[currentKey] === "number"
        ) {
          const elementToPush = generateElement(currentKey, data[currentKey]);
          result.push(elementToPush);
        } else {
          const nested = generateData(data[currentKey]);
          result.push(...nested);
        }
        return result;
      }, []);
      return newData;
    }

    if (quote.kind === "national_sbo") {
      return (
        <Card
          title={
            <>
              {quote.number} <StatusTag status={quote.state} />
            </>
          }
        >
          <Space direction="vertical">
            <Title level={4}>Quote Information</Title>
            <Descriptions column={1} layout="vertical" size="small">
              <Descriptions.Item label="Submitted at ">
                {moment(quote.created_at).format("MM/DD/YYYY")}
              </Descriptions.Item>
            </Descriptions>

            <Title level={4}>Client information</Title>
            <Descriptions column={1} layout="vertical" size="small">
              <Descriptions.Item label="Member Name">
                {quote.member.name}
              </Descriptions.Item>
              <Descriptions.Item label="Contact Name">
                {quote.contact_name}
              </Descriptions.Item>
              <Descriptions.Item label="Project Name">
                {payload.project_name}
              </Descriptions.Item>
              <Descriptions.Item label="Country for SBO service">
                {payload.country_for_sbo_service}
              </Descriptions.Item>
              <Descriptions column={1} layout="vertical" size="small">
                {quote.attachment_url && (
                  <Descriptions.Item label="Attachment">
                    <Button
                      type="link"
                      onClick={() => window.open(quote.attachment_url)}
                    >
                      {quote.attachment_name}
                    </Button>
                  </Descriptions.Item>
                )}
                {quote.file_url && (
                  <Descriptions.Item label="File">
                    <Button
                      type="link"
                      onClick={() => window.open(quote.file_url)}
                    >
                      {quote.file_name}
                    </Button>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Descriptions>

            {payload.quote_type && (
              <>
                <Title level={4}>Quote type</Title>
                {payload.quote_type}
              </>
            )}

            {payload.quote_type && payload.quote_type.toLowerCase().includes('bing') && (
              <>
                <Title level={4}>Bing Keywords</Title>
                {payload.keywords && payload.keywords.map((k) => (
                  <Tag color="gray">
                    {k.keyword_phrase.toUpperCase()} - {k.tag_phrase.toUpperCase()}
                  </Tag>
                ))}
              </>
            )}

            {payload.youtube_keywords && (
              <>
                <p></p>
                <Title level={4}>YouTube Keywords</Title>
                <Space direction="vertical">
                  {payload.youtube_keywords.map((k) => (
                    <Tag color="gray">
                      {k.keyword_phrase.toUpperCase()} -{" "}
                      {k.tag_phrase.toUpperCase()}
                    </Tag>
                  ))}
                </Space>
                <p></p>
              </>
            )}
          </Space>
        </Card>
      );
    } else {
      return (
        <Card
          title={
            <>
              {quote.number} <StatusTag status={quote.state} />
            </>
          }
        >
          <Descriptions column={1} layout="vertical" size="small">
            <Descriptions.Item label="Member Name">
              {quote.member.name}
            </Descriptions.Item>
            <Descriptions.Item label="Submitted at ">
              {moment(quote.created_at).format("MM/DD/YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Contact Name">
              {quote.contact_name}
            </Descriptions.Item>
            {generateData(quote.payload)}
            {quote.attachment_url && (
              <Descriptions.Item label="Attachment">
                <Button
                  type="link"
                  onClick={() => window.open(quote.attachment_url)}
                >
                  {quote.attachment_name}
                </Button>
              </Descriptions.Item>
            )}
            {quote.file_url && (
              <Descriptions.Item label="File">
                <Button type="link" onClick={() => window.open(quote.file_url)}>
                  {quote.file_name}
                </Button>
              </Descriptions.Item>
            )}
          </Descriptions>
        </Card>
      );
    }
  }
}

export default QuoteInfo;
