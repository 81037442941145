import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'antd';
import ThreadDetails from './ThreadDetails';
import {
  decreaseUnreadConversationMessagesCount,
  decreaseUnreadOrderMessagesCount,
  decreaseUnreadQuoteMessagesCount
} from '../../actions/profileActions';
import { fetchInbox } from '../../actions/inboxActions';
import parseHtml from '../../util/parseHtml';

class List extends React.Component {
  state = {
    detailVisible: this.props.showContainer || false,
    activeContainer: this.props.containerId || null,
    activeContainerType: this.props.containerType || 'VendorQuote',
    pagination: {
      current: 1,
      showSizeChanger: true,
      total: this.props.totalThreads,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    }
  };

  showDetails = (record) => {
    this.setState({
      detailVisible: true,
      activeContainer: record.container_id,
      activeContainerType: record.container_type
    })
    this.props.searchInputVisible(false);

    const { dispatch, unreadQuoteMessages, unreadOrderMessages, unreadConversationMessages } = this.props;

    if (record.unread) {
      dispatch(decreaseUnreadConversationMessagesCount(unreadConversationMessages));

      if (record.container_type === "VendorQuote") { dispatch(decreaseUnreadQuoteMessagesCount(unreadQuoteMessages)) }
      if (record.container_type === "VendorOrder") { dispatch(decreaseUnreadOrderMessagesCount(unreadOrderMessages)) }
    }
  }

  onBack = () => {
    this.setState({
      detailVisible: false,
      activeContainer: null,
      activeContainerType: null,
    })
    this.props.searchInputVisible(true);
    this.props.dispatch(fetchInbox());
  }

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
    });
    this.props.onTableChange(pagination);
  };

  render() {
    const { loading, threads, mobileView } = this.props;
    const { activeContainer, detailVisible, activeContainerType } = this.state;
    let columns = [
      {
        title: 'From',
        mobile: true,
        render: (record, index) => (record.author_name),
      },
      {
        title: 'Subject',
        mobile: true,
        render: (record, _index) => record.subject_name || 'N/A',
      },
      {
        title: 'Order Number',
        dataIndex: 'order_number',
        render: (order_number) => order_number || 'N/A',
        mobile: true,
      },
      {
        title: 'Company',
        dataIndex: 'company_name',
        mobile: true,
      },
      {
        title: 'Received at',
        dataIndex: 'created_at',
        render: (datetime) => moment(datetime).format('MM/DD/YYYY'),
      },
      {
        title: 'Message',
        mobile: true,
        render: (record, _index) => parseHtml(record.message),
      },
    ]

    const { pagination } = this.state;
    pagination.total = this.props.totalThreads;
    pagination.current = this.props.page;
    const mobileColumns = columns.filter((column) => column.mobile)
    return (
      <>
        {!detailVisible &&
          <Table
            columns={mobileView ? mobileColumns : columns}
            rowKey={record => record.id}
            dataSource={threads}
            rowClassName={record => record.unread ? 'inbox-row data-row active-row': 'inbox-row data-row'}
            loading={loading}
            pagination={pagination}
            onChange={this.handleTableChange}
            onRow={(record) => ({
              onClick: () => { this.showDetails(record); }
            })}
            size="middle"
          />}
        {activeContainer && detailVisible &&
          <ThreadDetails
            onBack={this.onBack}
            containerId={activeContainer}
            containerType={activeContainerType} />
        }
      </>
    );
  }
}

List.propTypes = {
  loading: PropTypes.bool.isRequired,
  mobileView: PropTypes.bool.isRequired,
  message_threads: PropTypes.array,
  onTableChange: PropTypes.func.isRequired,
  threads: PropTypes.array.isRequired,
  totalThreads: PropTypes.number,
  containerId: PropTypes.number,
  containerType: PropTypes.string,
  showContainer: PropTypes.bool,
  searchInputVisible: PropTypes.func,
  page: PropTypes.number,
};

const mapStateToProps = state => ({
  unreadQuoteMessages: state.profile.unreadQuoteMessages,
  unreadOrderMessages: state.profile.unreadOrderMessages,
  unreadConversationMessages: state.profile.unreadConversationMessages,
});
export default connect(mapStateToProps)(List);
