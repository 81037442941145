import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Table, Space, Form, InputNumber, Button, Modal, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import StatusTag from '../shared/StatusTag';
import SubmitQuoteModal from './SubmitQuoteModal';
import { ProductsWithETA } from "../../util/productConstants";
import { humanizeText } from "../../util/text";

class List extends React.Component {
  formRef = React.createRef();

  state = {
    data: this.props.quotes,
    showModal: false,
    selectedQuote: null,
    pagination: {
      current: 1,
      showSizeChanger: true,
      total: this.props.totalQuotes,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    },
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
    });
    this.props.onTableChange(pagination, filters, sorter);
  };

  hasAmounts = (quote) => {
    const amount =
      quote.quote_amounts &&
      quote.quote_amounts.map((q) => q.amount).reduce((a, b) => {
        return parseInt(a) + parseInt(b);
      }, 0);
    return amount > 0;
  };

  onSubmitQuote = () => {
    if (ProductsWithETA.includes(this.state.selectedQuote.kind)) {
      this.formRef.current
        .validateFields()
        .then((values) => {
          this.props.onQuoteSubmit(
            this.state.selectedQuote.id,
            { eta: values.eta }
          );
          this.setState({ showModal: false, selectedQuote: null });
        })
        .catch((info) => {
          console.log('Validate Failed:', info);
        });
    } else {
      this.props.onQuoteSubmit(this.state.selectedQuote.id, { eta: null });
      this.setState({ showModal: false, selectedQuote: null });
    }
  }

  generateActions = (quote) => {
    let actions = [];
    const hasAmounts = this.hasAmounts(quote);
    actions.push(<Link to={`/quotes/${quote.id}`}>Edit</Link>);
    if (quote.state === 'requested' && hasAmounts) {
      actions.push(
        <Button
          type="link"
          onClick={() => {
            this.setState({ showModal: true, selectedQuote: quote });
          }}
        >
          Submit quote
        </Button>
      );
    }
    return actions;
  };

  render() {
    const { loading, quotes, mobileView } = this.props;
    let columns = [
      {
        title: 'Quote ID',
        dataIndex: 'number',
        sorter: true,
      },
      {
        title: 'Kind',
        dataIndex: 'kind',
        sorter: true,
        render: (kind, record) => kind === 'pr_article' ? 'PR article' : humanizeText(kind)
      },
      {
        title: 'Member',
        dataIndex: 'name',
        sorter: true,
        render: (quote, record) => record.member.name,
      },
      {
        title: 'Company',
        dataIndex: 'company_name',
        sorter: true,
        render: (quote, record) => record.member.company_name,
      },
      {
        title: 'Submitted at',
        dataIndex: 'created_at',
        sorter: true,
        render: (datetime) => moment(datetime).format('MM/DD/YYYY'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        mobile: true,
        filterMultiple: false,
        render: (status, record) => <StatusTag status={record.state} />,
        filters: [
          { text: 'Draft', value: 'draft' },
          { text: 'Requested', value: 'requested' },
          { text: 'Submitted', value: 'submitted' },
          { text: 'Cancelled', value: 'cancelled' },
          { text: 'Paid', value: 'paid' },
        ],
      },
      {
        title: 'Action',
        mobile: true,
        dataIndex: 'id',
        render: (text, record) => (
          <Space size="middle">{this.generateActions(record)}</Space>
        ),
        width: '220px',
      },
    ];

    const mobileColumns = columns.filter((column) => column.mobile);
    const { pagination } = this.state;
    pagination.total = this.props.totalQuotes;
    pagination.current = this.props.page;

    return (
      <>
        <Table
          columns={mobileView ? mobileColumns : columns}
          rowKey={(record) => record.id}
          dataSource={quotes}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
          rowClassName={(record) =>
            record.unread ? 'data-row active-row' : 'data-row'
          }
          size="middle"
          scroll={{ x: 700 }}
        />
        <SubmitQuoteModal
          visible={this.state.showModal}
          onSubmit={this.onSubmitQuote}
          onCancel={() => this.setState({ showModal: false, selectedQuote: null })}
          formRef={this.formRef}
          selectedQuote={this.state.selectedQuote}
        />        
      </>
    );
  }
}

List.propTypes = {
  totalQuotes: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  quotes: PropTypes.array.isRequired,
  onTableChange: PropTypes.func.isRequired,
  onQuoteSubmit: PropTypes.func.isRequired,
};

export default List;
