import React from "react";
import moment from "moment";
import { Descriptions, Typography, Input } from "antd";
const { Title } = Typography;

const WebsiteOptimization = ({ resource }) => {
  return (
    <>
      <Title level={4}>Order Information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Full business name">{resource.business_name}</Descriptions.Item>
        <Descriptions.Item label="Business website">{resource.business_website}</Descriptions.Item>
        <Descriptions.Item label="Keywords to optimize">{resource.keywords}</Descriptions.Item>
        <Descriptions.Item label="Notes">{resource.comments}</Descriptions.Item>
      </Descriptions>

      <Title level={4}>Access Information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Admin username">{resource.admin_user}</Descriptions.Item>
        <Descriptions.Item label="Admin password">
          <Input.Password value={resource.admin_password} />
        </Descriptions.Item>
        <Descriptions.Item label="Admin URL">{resource.admin_url}</Descriptions.Item>
      </Descriptions>

      {resource.vendor_quote && (
        <>
          <Title level={4}>Quote Information</Title>
          <Descriptions column={1} layout="vertical" size="small">
            <Descriptions.Item label="Quote number">
              {resource.vendor_quote.number}
            </Descriptions.Item>
            <Descriptions.Item label="Quote date">
              {moment(resource.vendor_quote.created_at).format("MM/DD/YYYY")}
            </Descriptions.Item>
            {resource.vendor_quote.quote_amounts.map((quote_amount) => (
              <Descriptions.Item label={quote_amount.label}>
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(quote_amount.amount)}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </>
      )}
    </>
  );
};

export default WebsiteOptimization;
