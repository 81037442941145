import React from "react";
import { Row, Col, Form, Input } from "antd";
import KeywordInputRow from "./KeywordInputRow";

const PlatformBlock = ({ platform, blocks, keywordIndex, handleInputChange, isKeywordCombinationValid }) => {
  return (
    <div key={platform} style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", padding: "10px", marginTop: "10px", backgroundColor: "#f5f5f5" }}>
      {blocks.map((block, blockIndex) => (
        <KeywordInputRow
          key={blockIndex}
          block={block}
          blockIndex={blockIndex}
          keywordIndex={keywordIndex}
          platform={platform}
          handleInputChange={handleInputChange}
          isKeywordCombinationValid={isKeywordCombinationValid}
        />
      )
      )}
    </div>
  );
};

export default PlatformBlock;
