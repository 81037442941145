import _ from "lodash";
import moment from "moment";
import React from "react";
import CurrencyFormat from 'react-currency-format';
import { Descriptions, Typography, Tag, Space } from "antd";
const { Title } = Typography;

const LsaOrder = ({ resource }) => {
  const { payload } = resource;
  return (
    <>
      <Title level={4}>Contact & business information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Email">{payload.email}</Descriptions.Item>
        <Descriptions.Item label="Business name">{payload.business_name}</Descriptions.Item>
        <Descriptions.Item label="First name">{payload.first_name}</Descriptions.Item>
        <Descriptions.Item label="Last name">{payload.last_name}</Descriptions.Item>
        <Descriptions.Item label="Title">{payload.title}</Descriptions.Item>
        <Descriptions.Item label="Business headquarters address">{payload.business_headquarters}</Descriptions.Item>
        <Descriptions.Item label="Phone number">{payload.phone_number}</Descriptions.Item>
        <Descriptions.Item label="Company website">{payload.company_website}</Descriptions.Item>
        <Descriptions.Item label="Corporate structure">{payload.coroporate_structure}</Descriptions.Item>
        <Descriptions.Item label="Operating under a DBA?">{payload.has_dba}</Descriptions.Item>
        <Descriptions.Item label="DBA">{payload.dba}</Descriptions.Item>
      </Descriptions>

      <Title level={4}>Insurance information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Has liability insurance?">{payload.has_liability_insurance}</Descriptions.Item>
        <Descriptions.Item label="Coverage limit">
          <CurrencyFormat value={payload.insurance_coverage_limit} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </Descriptions.Item>
        <Descriptions.Item label="Insurance expireation date">{payload.insurance_expiration_date && <>{moment(payload.insurance_expiration_date).format('MM/DD/YYYY')}</>}</Descriptions.Item>
        <Descriptions.Item label="Insurance certificate">
          <a target="_blank" href={resource.insurance_certificate.url}>
            {resource.insurance_certificate.name}
          </a>
        </Descriptions.Item>
      </Descriptions>

      <Title level={4}>License information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="License type">{payload.license_type}</Descriptions.Item>
        <Descriptions.Item label="License number">{payload.license_number}</Descriptions.Item>
        <Descriptions.Item label="License expiration date">{payload.license_expiration_date && <>{moment(payload.license_expiration_date).format('MM/DD/YYYY')}</>}</Descriptions.Item>
        <Descriptions.Item label="License">
          {resource.license &&
            <a target="_blank" href={resource.license.url}>
              {resource.license.name}
            </a>
          }
        </Descriptions.Item>
      </Descriptions>

      <Title level={4}>Google services</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Started Google Guarantee/Screening process before?">{payload.started_google_screening}</Descriptions.Item>
        <Descriptions.Item label="Google screening results">{payload.google_screening_results}</Descriptions.Item>
        <Descriptions.Item label="Has Google Badge?">{payload.has_google_badge}</Descriptions.Item>
        <Descriptions.Item label="Existing Google customer IDs">{payload.existing_google_customer_ids}</Descriptions.Item>
        <Descriptions.Item label="Existing Google areas">{payload.exisitng_google_areas}</Descriptions.Item>
        <Descriptions.Item label="Has business profile?">{payload.has_google_business_profile}</Descriptions.Item>
        <Descriptions.Item label="Google profile name">{payload.existing_google_business_profile_name}</Descriptions.Item>
        <Descriptions.Item label="Desired number of weekly leads">{payload.desired_weekly_leads_amount}</Descriptions.Item>
        <Descriptions.Item label="Monthly ad budget">
          <CurrencyFormat value={payload.monthly_ad_budget} displayType={'text'} thousandSeparator={true} prefix={'$'} / >
        </Descriptions.Item>
      </Descriptions>

      <Title level={4}>Additional business details</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Areas serviced">{payload.areas_serviced}</Descriptions.Item>
        <Descriptions.Item label="Business type">{payload.business_type}</Descriptions.Item>
        <Descriptions.Item label="Company attributes">
          { payload.company_attributes && 
            <Space direction="vertical">
              {payload.company_attributes.map(t => <Tag color='gray'>{t.toUpperCase()}</Tag>)}
            </Space>
          }
        </Descriptions.Item>
        <Descriptions.Item label="Operating hours">{payload.operating_hours && payload.operating_hours.join(" - ")}</Descriptions.Item>
      </Descriptions>
      
      <Title level={4}>LSA COVID-19 precautions</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Service">
          { payload.covid_19_service_precautions && 
            <Space direction="vertical">
              {payload.covid_19_service_precautions.map(t => <Tag color='gray'>{t.toUpperCase()}</Tag>)}
            </Space>
          }
        </Descriptions.Item>
        <Descriptions.Item label="Health & safety">
          { payload.covid_19_health_safety_precautions && 
            <Space direction="vertical">
              {payload.covid_19_health_safety_precautions.map(t => <Tag color='gray'>{t.toUpperCase()}</Tag>)}
            </Space>
          }
        </Descriptions.Item>
        <Descriptions.Item label="Payments">
          { payload.covid_19_payments_precautions && 
            <Space direction="vertical">
              {payload.covid_19_payments_precautions.map(t => <Tag color='gray'>{t.toUpperCase()}</Tag>)}
            </Space>
          }
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default LsaOrder;
