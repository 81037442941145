import { authHeader, buildSortString, buildFilterString } from '../util/api';
import { snakeCase } from 'lodash';
import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_MESSAGES_BEGIN = 'FETCH_MESSAGES_BEGIN';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE';

export const CREATE_MESSAGE_BEGIN = 'CREATE_MESSAGE_BEGIN';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_FAILURE = 'CREATE_MESSAGE_FAILURE';
export const CREATE_MESSAGE_CLEAR = 'CREATE_MESSAGE_CLEAR';

export const MARK_AS_UNREAD_BEGIN = 'MARK_AS_UNREAD_BEGIN';
export const MARK_AS_UNREAD_SUCCESS = 'MARK_AS_UNREAD_SUCCESS';
export const MARK_AS_UNREAD_FAILURE = 'MARK_AS_UNREAD_FAILURE';

export const markAsUnreadBegin = () => ({
  type: MARK_AS_UNREAD_BEGIN
});

export const markAsUnreadSuccess = () => ({
  type: MARK_AS_UNREAD_SUCCESS
});

export const markAsUnreadFailure = error => ({
  type: MARK_AS_UNREAD_FAILURE,
  payload: { error }
});

export function markAsUnread(containerType, containerId) {
  return dispatch => {
    dispatch(markAsUnreadBegin());
    return axios.put(
      `${BASE_API_URL}/${snakeCase(containerType)}s/${containerId}/mark_unread.json`,
      {},
      {
        headers: authHeader()
      }
    )
    .then(() => {
      dispatch(markAsUnreadSuccess());
    })
    .catch(error => dispatch(markAsUnreadFailure(error)));
  };
}

export const fetchMessagesBegin = () => ({
  type: FETCH_MESSAGES_BEGIN
});

export const fetchMessagesSuccess = (messages) => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: { messages }
});

export const fetchMessagesFailure = error => ({
  type: FETCH_MESSAGES_FAILURE,
  payload: { error }
});

export function fetchMessages(container_type, id) {
  const controller = `${snakeCase(container_type)}s`;

  return dispatch => {
    dispatch(fetchMessagesBegin());
    return axios.get(`${BASE_API_URL}/${controller}/${id}/messages.json`, {
      headers: authHeader()
    })
      .then(json => {
        dispatch(fetchMessagesSuccess(json.data.messages));
        return json.data.messages;
      })
      .catch(error => dispatch(fetchMessagesFailure(error)));
  };
}

export const createMessageBegin = () => ({
  type: CREATE_MESSAGE_BEGIN
});

export const createMessageSuccess = (message) => ({
  type: CREATE_MESSAGE_SUCCESS,
  payload: { message }
});

export const createMessageFailure = error => ({
  type: CREATE_MESSAGE_FAILURE,
  payload: { error }
});

export const createMessageClear = () => ({
  type: CREATE_MESSAGE_CLEAR
});

export function createMessage(container_type, id, value) {
  const controller = `${snakeCase(container_type)}s`;

  return dispatch => {
    dispatch(createMessageBegin());
    return axios.post(`${BASE_API_URL}/${controller}/${id}/messages.json`,
      JSON.stringify({ message: value }),
      { headers: authHeader() })
      .then(json => {
        dispatch(createMessageSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(createMessageFailure(error.response.data)));
  };
}
