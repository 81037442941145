import {
  FETCH_MESSAGES_BEGIN,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILURE,

  CREATE_MESSAGE_BEGIN,
  CREATE_MESSAGE_FAILURE,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_CLEAR,

  MARK_AS_UNREAD_BEGIN,
  MARK_AS_UNREAD_FAILURE,
  MARK_AS_UNREAD_SUCCESS,

} from '../actions/messageActions';

const initialState = {
  items: [],
  loading: false,
  error: null,
  success: null,
  createMessageError: null,
  createMessageSuccess: null,
  createMessageLoading: false,
};

export default function messageReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MESSAGES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.messages,
      };
    case FETCH_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };


    case CREATE_MESSAGE_BEGIN:
      return {
        ...state,
        createMessageLoading: true,
        createMessageError: null
      };

    case CREATE_MESSAGE_SUCCESS:
      return {
        ...state,
        createMessageLoading: false,
        message: action.payload.message,
        createMessageSuccess: true
      };

    case CREATE_MESSAGE_FAILURE:
      return {
        ...state,
        createMessageLoading: false,
        createMessageError: action.payload.error,
      };

    case CREATE_MESSAGE_CLEAR:
      return {
        ...state,
        createMessageLoading: false,
        createMessageSuccess: null,
        createMessageError: null,
      };
    case MARK_AS_UNREAD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MARK_AS_UNREAD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case MARK_AS_UNREAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}