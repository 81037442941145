import { authHeader, buildSortString, buildFilterString } from '../util/api';
import _ from 'lodash';
import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_QUOTES_BEGIN = 'FETCH_QUOTES_BEGIN';
export const FETCH_QUOTES_SUCCESS = 'FETCH_QUOTES_SUCCESS';
export const FETCH_QUOTES_FAILURE = 'FETCH_QUOTES_FAILURE';
export const FETCH_QUOTE_BEGIN = 'FETCH_QUOTE_BEGIN';
export const FETCH_QUOTE_SUCCESS = 'FETCH_QUOTE_SUCCESS';
export const FETCH_QUOTE_FAILURE = 'FETCH_QUOTE_FAILURE';
export const FETCH_QUOTE_CLEAR = 'FETCH_QUOTE_CLEAR';
export const UPDATE_QUOTE_BEGIN = 'UPDATE_QUOTE_BEGIN';
export const UPDATE_QUOTE_SUCCESS = 'UPDATE_QUOTE_SUCCESS';
export const UPDATE_QUOTE_FAILURE = 'UPDATE_QUOTE_FAILURE';
export const UPDATE_QUOTE_CLEAR = 'UPDATE_QUOTE_CLEAR';

export const SUBMIT_QUOTE_TO_MEMBER_BEGIN = 'SUBMIT_QUOTE_TO_MEMBER_BEGIN';
export const SUBMIT_QUOTE_TO_MEMBER_SUCCESS = 'SUBMIT_QUOTE_TO_MEMBER_SUCCESS';
export const SUBMIT_QUOTE_TO_MEMBER_FAILURE = 'SUBMIT_QUOTE_TO_MEMBER_FAILURE';
export const SUBMIT_QUOTE_TO_MEMBER_CLEAR = 'SUBMIT_QUOTE_TO_MEMBER_CLEAR';

export const TRANSFER_VENDOR_QUOTE_BEGIN = 'TRANSFER_VENDOR_QUOTE_BEGIN';
export const TRANSFER_VENDOR_QUOTE_SUCCESS = 'TRANSFER_VENDOR_QUOTE_SUCCESS';
export const TRANSFER_VENDOR_QUOTE_FAILURE = 'TRANSFER_VENDOR_QUOTE_FAILURE';

export const fetchQuotesBegin = () => ({
  type: FETCH_QUOTES_BEGIN
});

export const fetchQuotesSuccess = (quotes, totalItems) => ({
  type: FETCH_QUOTES_SUCCESS,
  payload: { quotes, totalItems }
});

export const fetchQuotesFailure = error => ({
  type: FETCH_QUOTES_FAILURE,
  payload: { error }
});

export function fetchQuotes(page = 1, pageSize = 10, filters, sorter, query) {
  return dispatch => {
    dispatch(fetchQuotesBegin());
    let sortString = buildSortString(sorter);
    let filterString = buildFilterString(filters);
    let queryString = '';
    if (query !== undefined && query !== '') {
      queryString = `&q[number_or_contact_user_first_name_or_contact_user_last_name_cont]=${query}`
    }
    return axios.get(`${BASE_API_URL}/vendor_quotes.json?page=${page}&per_page=${pageSize}${sortString}${filterString}${queryString}`, {
      headers: authHeader()
    })
      .then(json => {
        dispatch(fetchQuotesSuccess(json.data.vendor_quotes, json.data.total_entries));
        return json.data.vendor_quotes;
      })
      .catch(error => dispatch(fetchQuotesFailure(error)));
  };
}

export const fetchQuoteBegin = () => ({
  type: FETCH_QUOTE_BEGIN
});

export const fetchQuoteSuccess = (quote) => ({
  type: FETCH_QUOTE_SUCCESS,
  payload: { quote }
});

export const fetchQuoteFailure = error => ({
  type: FETCH_QUOTE_FAILURE,
  payload: { error }
});

export function fetchQuote(id) {
  return dispatch => {
    dispatch(fetchQuoteBegin());
    return axios.get(`${BASE_API_URL}/vendor_quotes/${id}.json`, {
      headers: authHeader()
    })
      .then(json => {
        dispatch(fetchQuoteSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(fetchQuoteFailure(error)));
  };
}

export const fetchQuoteClear = () => ({
  type: FETCH_QUOTE_CLEAR
});


export const updateQuoteBegin = () => ({
  type: UPDATE_QUOTE_BEGIN
});

export const updateQuoteSuccess = (quote) => ({
  type: UPDATE_QUOTE_SUCCESS,
  payload: { quote }
});

export const updateQuoteFailure = error => ({
  type: UPDATE_QUOTE_FAILURE,
  payload: { error }
});

export const updateQuoteClear = () => ({
  type: UPDATE_QUOTE_CLEAR
});

export function updateQuote(id, values) {
  return dispatch => {
    dispatch(updateQuoteBegin());
    return axios.put(`${BASE_API_URL}/vendor_quotes/${id}.json`,
      JSON.stringify({ vendor_quote: createQuoteFromValues(values) }),
      { headers: authHeader() })
      .then(json => {
        dispatch(updateQuoteSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(updateQuoteFailure(error.response.data)));
  };
}

export const submitQuoteBegin = () => ({
  type: SUBMIT_QUOTE_TO_MEMBER_BEGIN
});

export const submitQuoteSuccess = (quote) => ({
  type: SUBMIT_QUOTE_TO_MEMBER_SUCCESS,
  payload: { quote }
});

export const submitQuoteFailure = error => ({
  type: SUBMIT_QUOTE_TO_MEMBER_FAILURE,
  payload: { error }
});

export const submitQuoteClear = () => ({
  type: SUBMIT_QUOTE_TO_MEMBER_CLEAR
});

export function submitQuote(id, values) {
  return dispatch => {
    dispatch(submitQuoteBegin());
    return axios.put(`${BASE_API_URL}/vendor_quotes/${id}/submit.json`,
      JSON.stringify({ quote_submission: _.pick(values, ['eta'])}),
      { headers: authHeader() })
      .then(json => {
        dispatch(submitQuoteSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(submitQuoteFailure(error.response.data)));
  };
}

function createQuoteFromValues(values) {
  let result = _.pick(values, ['notes']);
  result.quote_amounts = values.quote_amounts.filter(quote_amount => quote_amount.label !== undefined && quote_amount.amount !== undefined)
  return result;
}

export const transferVendorQuoteBegin = () => ({
  type: TRANSFER_VENDOR_QUOTE_BEGIN
});

export const transferVendorQuoteSuccess = () => ({
  type: TRANSFER_VENDOR_QUOTE_SUCCESS
});

export const transferVendorQuoteFailure = error => ({
  type: TRANSFER_VENDOR_QUOTE_FAILURE,
  payload: { error }
});

export function transferVendorQuote(quoteId) {
  return dispatch => {
    dispatch(transferVendorQuoteBegin());
    return axios.post(`${BASE_API_URL}/vendor_quotes/${quoteId}/transfers`,
      {},
      { headers: authHeader() })
      .then(() => {
        dispatch(transferVendorQuoteSuccess());
      })
      .catch(error => {
        const errorMessage = error.response ? error.response.data : { errors: ['An error has occured'] };
        dispatch(transferVendorQuoteFailure(errorMessage));
        throw error;
      });
  };
}