import { Button, Form } from "antd";
import React from "react"
import { connect } from "react-redux";
import ErrorDisplay from "../shared/ErrorDisplay";
import InputField from "../shared/inputs/InputField";
import PictureWallField from "../shared/inputs/PictureWallField";
import { updateArticle } from "../../actions/prArticleActions";

class ConfirmationLinksForm extends React.Component {

  handleFinish = values => {
    const { article } = this.props;
    if (article.state === 'approved') {
      values['op'] = 'post';
    }
    this.props.dispatch(updateArticle(article.id, values));
  };

  normFile = e => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length > 1) {
      return [e.fileList[1]];
    }
    return e && e.fileList;
  };

  render() {
    const { article, error, loading } = this.props;

    const initialValues = {
      ...article,
      images: article.attachment_json.post_images
    }

    return (
      <>
        <Form layout="vertical" onFinish={this.handleFinish} initialValues={initialValues} name="confirmation_links" autoComplete="off">
          <ErrorDisplay error={error} />
          <InputField attribute="blog_link" required={false} />
          <InputField attribute="facebook_link" required={false} />
          <InputField attribute="linkedin_link" required={false} />
          <PictureWallField attribute='images' maxLimit={4} kind="article_post" fileList={article.attachment_json.post_images} required={false} />
          <Button htmlType="submit" type="primary" loading={loading}>
            Submit
          </Button>
        </Form>
      </>
    )
  }
}

const mapStateToProps = state => ({
  error: state.prArticle.error,
  loading: state.prArticle.loading,
});

export default connect(mapStateToProps)(ConfirmationLinksForm);