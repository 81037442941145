import _ from "lodash";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Descriptions, Typography, Tag, Space, Divider } from "antd";
import { SBOpresenceAttributes } from "../../../util/productConstants";
import { capitalizeText } from "../../../util/text";
const { Title } = Typography;

const SboOrder = ({ resource }) => {
  const { payload, keyword_blocks } = resource;

  let groupedByPlatform = {}
  let platformType = payload.platform_type;
  if (resource.keywords_converted){
    groupedByPlatform = resource.converted_keywords_payload.reduce((acc, item) => {
      Object.keys(item).forEach(platform => {
        if (!acc[platform]) {
          acc[platform] = [];
        }
        acc[platform] = acc[platform].concat(item[platform]);
      });
      return acc;
    }, {});

    const uniquePlatforms = resource.converted_keywords_payload.reduce((acc, obj) => {
        Object.keys(obj).forEach(key => {
            acc.add(key);
        });
        return acc;
    }, new Set());

    uniquePlatforms.add(payload.platform_type.replace(/google \+ /gi, ""))
    platformType = Array.from(uniquePlatforms).join(" + ")
  }

  const platformLabel = {
    youtube: 'YouTube',
    google: 'Google',
    bing: 'Bing',
  }

  return (
    <>
      <Title level={4}>Order Information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Platform type">
          {platformType}
        </Descriptions.Item>
        <Descriptions.Item label="Campaign type">
          {payload.campaign_type}
        </Descriptions.Item>
        {resource.vendor_quote && (
          <Descriptions.Item label="Quote">
            <Link to={`/quotes/${resource.vendor_quote.id}`}>
              {resource.vendor_quote.number}
            </Link>
          </Descriptions.Item>
        )}
      </Descriptions>

      {resource.vendor_quote && (
        <>
          <Title level={4}>Quote Information</Title>
          <Descriptions column={1} layout="vertical" size="small">
            <Descriptions.Item label="Quote number">
              {resource.vendor_quote.number}
            </Descriptions.Item>
            <Descriptions.Item label="Quote date">
              {moment(resource.vendor_quote.created_at).format("MM/DD/YYYY")}
            </Descriptions.Item>
            {resource.vendor_quote.quote_amounts.map((quote_amount) => (
              <Descriptions.Item label={quote_amount.label}>
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(quote_amount.amount)}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </>
      )}

      <Title level={4}>Client information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Company name">
          {payload.company_name}
        </Descriptions.Item>
        <Descriptions.Item label="Contact name">
          {payload.contact_name}
        </Descriptions.Item>
        <Descriptions.Item label="Contact title">
          {payload.contact_title}
        </Descriptions.Item>
        <Descriptions.Item label="Telephone number">
          {payload.contact_phone}
        </Descriptions.Item>
        <Descriptions.Item label="Email address">
          {payload.contact_email}
        </Descriptions.Item>
        <Descriptions.Item label="Website URL">
          {payload.company_website}
        </Descriptions.Item>
        <Descriptions.Item label="Legal address">
          {payload.legal_address}
        </Descriptions.Item>
        <Descriptions.Item label="Mailing address">
          {payload.mailing_address}
        </Descriptions.Item>
        <Descriptions.Item label="Client agreement">
          <a target="_blank" href={resource.intake_form.url}>
            {resource.intake_form.name}
          </a>
        </Descriptions.Item>
      </Descriptions>

      {payload.keywords && (
        <>
          <Title level={4}>Keywords</Title>
          <Space direction="vertical">
            {payload.keywords.map((k) => (
              <Tag color="gray">
                {k.keyword_phrase.toUpperCase()} - {k.tag_phrase.toUpperCase()}
              </Tag>
            ))}
          </Space>
        </>
     )} 

      {payload.youtube_keywords && (
        <>
          <p></p>
          <Title level={4}>YouTube Keywords</Title>
          <Space direction="vertical">
            {payload.youtube_keywords.map((k) => (
              <Tag color="gray">
                {k.keyword_phrase.toUpperCase()} - {k.tag_phrase.toUpperCase()}
              </Tag>
            ))}
          </Space>
          <p></p>
          <p></p>
        </>
      )}

      {keyword_blocks && (
        <>
          {Object.keys(keyword_blocks).map(platform => (
            <div key={platform}>
              <Title level={4}>{`${platformLabel[platform]} Keywords`}</Title>
              <Space direction="vertical">
                {keyword_blocks[platform].map(block => (
                  block.map(k => (
                      <Tag color='gray' key={`${k.keyword_phrase}-${k.tag_phrase}`}>
                        {k.keyword_phrase.toUpperCase()} - {k.tag_phrase.toUpperCase()}
                      </Tag>
                  ))
                ))}
              </Space>
              <Divider />
            </div>
          ))}
        </>
      )}

      {
        resource.keywords_converted && (
          <>
            <Title level={4}>Converted Keywords</Title>
            <Space direction="vertical">
              {
                Object.keys(groupedByPlatform).map((platform) => (
                  <div key={platform}> {/* Add a div wrapper with a unique key */}
                    <Title level={5}>{platform} Keywords</Title>
                    {groupedByPlatform[platform].map((entry, index) => (
                      <Tag color="gray" key={index}> {/* Ensure each Tag has a unique key */}
                        {entry.keyword_phrase.toUpperCase()} - {entry.tag_phrase.toUpperCase()}
                      </Tag>
                    ))}
                  </div>
                ))
              }
            </Space>
          </>
      )}

      { payload.social_media_profiles && (
        <>
          <Title level={4}>Digital presence information</Title>
          <Descriptions column={1} layout="vertical" size="small">
            <Descriptions.Item label="Available social media profiles">
              <Space direction="vertical">
                {payload.social_media_profiles.map((t) => (
                  <Tag color="gray">{t.toUpperCase()}</Tag>
                ))}
              </Space>
            </Descriptions.Item>
            {SBOpresenceAttributes.map((attr) => (
              <Descriptions.Item label={attr.label}>
                {capitalizeText(payload[attr.value])}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </>
      )}
    </>
  );
};

export default SboOrder;
