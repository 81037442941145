import React from "react";
import { Row, Col, Form, Input } from "antd";

const KeywordInputRow = ({ block, blockIndex, keywordIndex, platform, handleInputChange, isKeywordCombinationValid, form }) => {

  const validatePair = (_, value) => {
    const { tag_phrase } = block;
    if (!value && tag_phrase.trim() !== '') {
      return Promise.reject(new Error('Keyword is missing'));
    }
    return Promise.resolve();
  };

  return (
    <>
      {blockIndex === 0 && (
        <Row gutter={16} justify="center">
          <Col span={12}>
            <div style={{ textAlign: "center" }}>Keyword phrase</div>
          </Col>
          <Col span={12}>
            <div style={{ textAlign: "center" }}>Tag phrase</div>
          </Col>
        </Row>
      )}
      <Row gutter={16} style={{ marginTop: "2px" }}>
        <Col span={12}>
          <Form.Item
            name={[`keywordBlocks`, keywordIndex, platform, blockIndex, `keyword_phrase`]}
            rules={[
              {
                validator: validatePair
              },
              {
                validator: (_, value) => {
                  const validationResult = isKeywordCombinationValid(keywordIndex, blockIndex, platform, value, block.tag_phrase);
                  if (!validationResult.isValid) {
                    return Promise.reject(new Error(validationResult.message));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type="text"
              value={block.keyword_phrase}
              onChange={(e) =>
                handleInputChange(keywordIndex, blockIndex, platform, "keyword_phrase", e.target.value)
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[`keywordBlocks`, keywordIndex, platform, blockIndex, `tag_phrase`]}
            rules={[
              {
                validator: (_, value) => {
                  const { keyword_phrase } = block;

                  if (!value && keyword_phrase.trim() !== '') {
                    return Promise.reject(new Error('Tag is missing'));
                  }
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  const validationResult = isKeywordCombinationValid(keywordIndex, blockIndex, platform, block.keyword_phrase, value);
                  if (!validationResult.isValid) {
                    return Promise.reject(new Error(validationResult.message));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type="text"
              value={block.tag_phrase}
              onChange={(e) =>
                handleInputChange(keywordIndex, blockIndex, platform, "tag_phrase", e.target.value)
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default KeywordInputRow;
