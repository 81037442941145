import React from "react"
import PropTypes from "prop-types"

import { message, Button, Form, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { profilePhotoAuthHeader } from "../../util/api";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 1;
  if (!isLt2M) {
    message.error('Image must smaller than 1MB!');
  }
  return isJpgOrPng && isLt2M;
}

class PhotoForm extends React.Component {

  state = {
    loading: false,
    imageUrl: this.props.imageUrl
  };

  normFile = e => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
  };

  render() {
    const { kind, imageLabel, onSubmit } = this.props;

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    let { imageUrl } = this.state;

    return (
      <>
        <div style={{ width: '200px', marginRight: '1em' }}>
          <Form layout="vertical" onFinish={onSubmit}>
            <Form.Item
              label={imageLabel}
              name={kind}
              valuePropName="fileList"
              style={{ marginBottom: 0 }}
              getValueFromEvent={this.normFile}
            >
              <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={`${BASE_API_URL}/file_uploads`}
                beforeUpload={beforeUpload}
                onChange={this.handleChange}
                data={{ kind }}
                headers={profilePhotoAuthHeader()}
              >
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </Form.Item>

            <Button htmlType="submit" type="primary">
              Save
              </Button>
          </Form>
        </div>
      </>
    );

  }
}

PhotoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  imageUrl: PropTypes.string
};

export default PhotoForm;