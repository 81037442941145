import {
  CREATE_SUBMISSION_BEGIN,
  CREATE_SUBMISSION_FAILURE,
  CREATE_SUBMISSION_SUCCESS,
  CREATE_SUBMISSION_CLEAR,
} from '../actions/submissionActions';

const initialState = {
  loading: false,
  error: null,
  success: null,
};

export default function submissionReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_SUBMISSION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case CREATE_SUBMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      };

    case CREATE_SUBMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case CREATE_SUBMISSION_CLEAR:
      return {
        ...state,
        loading: false,
        success: null,
        error: null,
      };
    default:
      return state;
  }
}