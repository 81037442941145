import _ from "lodash";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Descriptions, Typography, Tag, Space } from "antd";
const { Title, Paragraph } = Typography;

const SeoV2Order = ({ resource }) => {
  const { payload } = resource;
  return (
    <>
      <Title level={4}>Order information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Project name">
          {resource.project_name}
        </Descriptions.Item>
        <Descriptions.Item label="Company name">
          {payload.company_name}
        </Descriptions.Item>
        <Descriptions.Item label="Contact name">
          {payload.contact_name}
        </Descriptions.Item>
        <Descriptions.Item label="Website URL">
          {resource.website_url}
        </Descriptions.Item>
        {resource.vendor_quote && (
          <Descriptions.Item label="Quote">
            <Link to={`/quotes/${resource.vendor_quote.id}`}>
              {resource.vendor_quote.number}
            </Link>
          </Descriptions.Item>
        )}
      </Descriptions>

      {resource.vendor_quote && (
        <>
          <Title level={4}>Quote Information</Title>
          <Descriptions column={1} layout="vertical" size="small">
            <Descriptions.Item label="Quote number">
              {resource.vendor_quote.number}
            </Descriptions.Item>
            <Descriptions.Item label="Quote date">
              {moment(resource.vendor_quote.created_at).format("MM/DD/YYYY")}
            </Descriptions.Item>
            {resource.vendor_quote.quote_amounts.map((quote_amount) => (
              <Descriptions.Item label={quote_amount.label}>
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(quote_amount.amount)}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </>
      )}

      <Title level={4}>Keywords</Title>
      <Space direction="vertical">
        {payload.display_keywords.map((k) => (
          <Tag color="gray">
            {k.toUpperCase()}
          </Tag>
        ))}
      </Space>

      <Title level={4}>SEO Questionnaire</Title>
      <Paragraph>Geographical regions</Paragraph>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Country">{payload.country}</Descriptions.Item>
        <Descriptions.Item label="State">{payload.region}</Descriptions.Item>
        <Descriptions.Item label="Cities">{payload.cities}</Descriptions.Item>
      </Descriptions>

      <Paragraph>Additional information</Paragraph>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="GMB Account ID">{payload.gmb_accounts}</Descriptions.Item>
        <Descriptions.Item label="Target audience / ideal customer">{payload.target_audience}</Descriptions.Item>
        <Descriptions.Item label="Target industries">{payload.target_industries}</Descriptions.Item>
        <Descriptions.Item label="Important site sections">{payload.important_site_sections}</Descriptions.Item>
        <Descriptions.Item label="Website previously penalized?">{payload.website_penalized}</Descriptions.Item>
        <Descriptions.Item label="Agreement">
          {resource.agreement &&
            <a target="_blank" href={resource.agreement.url}>
              {resource.agreement.name}
            </a>
          }
        </Descriptions.Item>
      </Descriptions>
      
    </>
  );
};

export default SeoV2Order;
