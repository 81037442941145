import React from 'react';
import styled from 'styled-components';
import ReactQuill, { Quill } from 'react-quill-with-table';
import QuillBetterTable from 'quill-better-table';
import 'react-quill-with-table/dist/quill.snow.css';
import 'quill-better-table/dist/quill-better-table.css';

Quill.register('modules/better-table', QuillBetterTable);

const Delta = Quill.import('delta');

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'bullet' }],
    ['link'],
    ['clean'],
  ],
  clipboard: {
    matchers: [
      [
        Node.ELEMENT_NODE,
        function (node, delta) {
          return delta.compose(
            new Delta().retain(delta.length(), {
              color: false,
              background: false,
              strike: false,
            })
          );
        },
      ],
    ],
  },
  table: false, // disable table module
  'better-table': {
    operationMenu: {
      items: {
        unmergeCells: {
          text: 'Another unmerge cells name',
        },
      },
    },
  },
  keyboard: {
    bindings: QuillBetterTable.keyboardBindings,
  },
};

const formats = ['bold', 'italic', 'underline', 'bullet', 'link'];

const Wrapper = styled.div`
  .ql-tooltip {
    z-index: 1;
    margin-left: 50px;
  }
`;

const RichTextEditor = ({ value, onChange, placeholder, style = {} }) => {

  const handleOnChange = (newVal) => {
    const regex = /<img[^>]+>/g;

    if (newVal.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      newVal = ''; // that's for handling empty tags
    }
    // Removing inline upload image
    if (regex.test(newVal)) {
      newVal = newVal.replace(regex, '\n');
    }
    onChange(newVal);
  };

  return (
    <Wrapper>
      <ReactQuill
        style={style}
        theme="snow"
        value={value || ''}
        modules={modules}
        formats={formats}
        onChange={handleOnChange}
        placeholder={placeholder}
      />
    </Wrapper>
  );
};

export default RichTextEditor;
