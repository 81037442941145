import { Button, Form, Upload, DatePicker } from "antd";
import React from "react"
import moment from 'moment'
import { connect } from "react-redux";
import ErrorDisplay from "../shared/ErrorDisplay";
import { profilePhotoAuthHeader } from "../../util/api";
import { UploadOutlined } from "@ant-design/icons";
import { updateArticle } from "../../actions/prArticleActions";
import InputField from "../shared/inputs/InputField";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

class CampaignPerformance extends React.Component {

  handleFinish = values => {
    const { article } = this.props;
    values['op'] = 'complete';
    this.props.dispatch(updateArticle(article.id, values));
  };

  normFile = e => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length > 1) {
      return [e.fileList[1]];
    }
    return e && e.fileList;
  };

  render() {
    const { article, error, loading } = this.props;
    const initialValues = {
      ...article,
      campaign_start_date: article.campaign_start_date ? moment(article.campaign_start_date) : null,
      campaign_end_date: article.campaign_start_date ? moment(article.campaign_end_date) : null,
      report: article.attachment_json.report
    }

    return (
      <>
        <Form layout="vertical" onFinish={this.handleFinish} initialValues={initialValues} name="dynamic_form_nest_item" autoComplete="off">
          <ErrorDisplay error={error} />
          <Form.Item label="Campaign start date" name="campaign_start_date" rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="Campaign end date" name="campaign_end_date" rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <InputField attribute="total_reach" type='number' />
          <InputField label='Total engagement' attribute="total_clicks" type='number' />
          <Form.Item
            label="Add Report PDF"
            name="report"
            valuePropName="fileList"
            getValueFromEvent={this.normFile}
          >
            <Upload name="file"
              accept='application/pdf'
              headers={profilePhotoAuthHeader()}
              action={`${BASE_API_URL}/file_uploads`} listType="picture">
              <Button>
                <UploadOutlined /> Click to upload
              </Button>
            </Upload>
          </Form.Item>

          <Button htmlType="submit" type="primary" loading={loading}>
            Submit
          </Button>
        </Form>
      </>
    )
  }
}

const mapStateToProps = state => ({
  error: state.prArticle.error,
  loading: state.prArticle.loading,
});

export default connect(mapStateToProps)(CampaignPerformance);