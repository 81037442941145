import axios from 'axios';
import { authHeader } from '../util/api';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_INBOX_BEGIN = 'FETCH_INBOX_BEGIN';
export const FETCH_INBOX_SUCCESS = 'FETCH_INBOX_SUCCESS';
export const FETCH_INBOX_FAILURE = 'FETCH_INBOX_FAILURE';

export const fetchInboxBegin = () => ({
  type: FETCH_INBOX_BEGIN
});

export const fetchInboxSuccess = (threads, totalItems) => ({
  type: FETCH_INBOX_SUCCESS,
  payload: { threads, totalItems }
});

export const fetchInboxFailure = error => ({
  type: FETCH_INBOX_FAILURE,
  payload: { error }
});

export function fetchInbox(page = 1, pageSize = 10, query = null) {
  return dispatch => {
    dispatch(fetchInboxBegin());
    let queryString = '';
    if (query) {
      queryString =
        `&q[container_of_VendorQuote_type_messages_message_or_container_of_VendorOrder_type_messages_message_or_container_of_VendorConversation_type_messages_message_cont]=${query}`
    }
    return axios.get(`${BASE_API_URL}/inbox.json?page=${page}&per_page=${pageSize}${queryString}`, {
      headers: authHeader()
    })
      .then(json => {
        dispatch(fetchInboxSuccess(json.data.threads, json.data.total_entries));
        return json.data.threads;
      })
      .catch(error => dispatch(fetchInboxFailure(error)));
  };
}
