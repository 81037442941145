export const BASE_INBOX_ROUTES = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '/inbox',
    breadcrumbName: 'Inbox',
  },
];

export const BASE_ACCOUNT_ROUTES = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '/account',
    breadcrumbName: 'Account',
  },
];

export const BASE_QUOTE_REQUEST_ROUTES = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '/quotes',
    breadcrumbName: 'Quotes',
  },
];

export const BASE_ORDER_ROUTES = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '/orders',
    breadcrumbName: 'Orders',
  },
];