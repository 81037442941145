import React from "react"
import { Upload, Modal, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { humanizeText } from '../../../util/text';
import { profilePhotoAuthHeader } from "../../../util/api";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class PictureWallField extends React.Component {
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: this.props.fileList || [],
  };

  normFile = e => {
    const { maxLimit = 3 } = this.props

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList.slice(0, maxLimit);
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.response.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.response.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.response.url.substring(file.response.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  render() {
    const { maxLimit = 3, attribute, required = true, label = null, help = null, kind } = this.props
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const displayLabel = label === null ? humanizeText(attribute) : label;
    const rules = required ? [{ required: true, message: `Please upload a ${displayLabel}` }] : [];
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <>
        <Form.Item
          label={displayLabel}
          name={attribute}
          rules={rules}
          extra={help}
          help={`Max ${maxLimit} images`}
          valuePropName="fileList"
          getValueFromEvent={this.normFile}
        >
          <Upload
            multiple
            name="image"
            headers={profilePhotoAuthHeader()}
            action={`${BASE_API_URL}/images`}
            listType="picture-card"
            data={{ kind }}
            onPreview={this.handlePreview}
            onChange={this.handleChange}
          >
            {fileList.length >= maxLimit ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    );
  }
}

export default PictureWallField