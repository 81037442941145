import React from "react";
import { Descriptions, Typography, Space } from "antd";
const { Title, Link } = Typography;

const AnswerBotKnowledgeFileUpload = ({ resource }) => {
  return (
    <>
      <Title level={4}>Order Information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Agent Name">{resource.agent_name}</Descriptions.Item>
        <Descriptions.Item label="Agent ID">{resource.agent_id}</Descriptions.Item>
      </Descriptions>

      {resource.files && (
        <>
          <Title level={4}>Files</Title>
          <Space direction="vertical">
            {resource.files.map((f) => (
              <Link key={f.url} href={f.url}>{f.name}</Link>
            ))}
          </Space>
        </>
     )} 
    </>
  );
};

export default AnswerBotKnowledgeFileUpload;
