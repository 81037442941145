import { Card, Col, Row, Skeleton, Tabs, message, Button } from "antd";
import React from "react"

import { connect } from "react-redux";
import { fetchOrder, fetchOrderClear } from "../../actions/ordersActions";
import { BASE_ORDER_ROUTES } from "../../util/routes";
import OrderInfo from "./OrderInfo";
import PageHeader from '../shared/PageHeader';
import Messages from "../shared/Messages";
import SubmissionForm from "./SubmissionForm";
import ConfirmationLinksForm from './ConfirmationLinksForm'
import PromotionInfo from './PromotionInfo'
import CampaignPerformance from './CampaignPerformance'
import ConfirmGoogleKeywordConvertModal from './ConfirmGoogleKeywordConvertModal'
import { updateArticleClear } from "../../actions/prArticleActions";
import GoogleKeywordConversionForm from './GoogleKeywordConversionForm'

const { TabPane } = Tabs;

const ALL_PLATFORMS = 'all platforms';
const GOOGLE_YOUTUBE = 'google + youtube';
const GOOGLE_BING = 'google + bing';

class OrderDetails extends React.Component {
  state = {
    showConfirmGoogleKeywordsConvertModal: false,
    showGoogleKeywordsConversionForm: false,
  }

  componentDidMount() {
    this.props.dispatch(fetchOrderClear());
    const { id } = this.props.match.params;
    this.props.dispatch(fetchOrder(id));
  }

  componentDidUpdate(prevProps, _prevState) {
    const { order } = this.props;

    if (prevProps.success !== this.props.success && this.props.success) {
      this.props.dispatch(updateArticleClear());
      message.success(`Article updated successfully`);
      this.props.dispatch(fetchOrder(order.id));
    }
  }

  toggleConfirmationModal = () => {
    this.setState({ 
      showConfirmGoogleKeywordsConvertModal: true
    });
  };

  showConvertKeywordButton = () => {
    const { order } = this.props
    if (!order || order.kind !== 'sbo') {
      return false;
    }

    const platformType = order.resource.payload.platform_type.toLowerCase();
    return !order.resource.keywords_converted && [ALL_PLATFORMS, GOOGLE_YOUTUBE, GOOGLE_BING].includes(platformType);
  };

  onConfirmGoogleKeywordConversion = () => {
    this.setState({
      showGoogleKeywordsConversionForm: true, 
      showConfirmGoogleKeywordsConvertModal: false,
    })
  }

  handleGoogleKeywordsConversionSuccess = (order) => {
    this.setState({
      showGoogleKeywordsConversionForm: false,
    });    
  }

  render() {
    const { order, mobileView } = this.props;
    const loading = (order == null || order === undefined);
    const defaultActiveKey = 'submissions'
    if (loading) {
      return (
        <div className="content-holder" style={{ background: '#fff' }}>
          <Skeleton />
        </div>
      )
    }
    else {
      const title = order.number;
      const routes = BASE_ORDER_ROUTES.concat([{
        path: `/orders/${order.id}`,
        breadcrumbName: `${order.number}`,
      }])
      return (
        <>
          <PageHeader title={title} routes={routes} />
          <Row className="content-holder order-details" style={{ background: '#fff' }}>
            {!mobileView &&
              <Col sm={9} xs={24}>
                <OrderInfo order={order} />
              </Col>
            }
            {order.kind === 'sbo' && this.state.showGoogleKeywordsConversionForm && 
              <Col sm={15} xs={24}>
                <div style={{ padding: '20px', margin: '20px', backgroundColor: '#fff', boxShadow: '0 1px 3px rgba(0,0,0,0.1)' }}>
                  <GoogleKeywordConversionForm order={order} handleGoogleKeywordsConversionSuccess={this.handleGoogleKeywordsConversionSuccess}/>
                </div>
              </Col>
            }
            {order && order.kind === 'sbo' &&
              <ConfirmGoogleKeywordConvertModal
                onCancel={() => this.setState({ showConfirmGoogleKeywordsConvertModal: false })}
                resource={order.resource}
                visible={this.state.showConfirmGoogleKeywordsConvertModal}
                onProceed={this.onConfirmGoogleKeywordConversion}
              />
             }
            { !this.state.showGoogleKeywordsConversionForm && 
              <Col sm={{ span: 14, offset: 1 }} xs={24}>
              
                <Tabs defaultActiveKey={defaultActiveKey} animated={false}>
                  {mobileView &&
                    <TabPane tab="Order Details" key="info">
                      <OrderInfo order={order} />
                    </TabPane>
                  }
                  {order.kind === 'pr_article' &&
                    <>
                      <TabPane tab="Submissions" key="submissions">
                        <Card>
                          <SubmissionForm article={order.resource} order_id={order.id} />
                        </Card>
                      </TabPane>
                      {order.resource.state !== 'pending' &&
                        <TabPane tab="Confirmation links" key="confirmation_links">
                          <Card>
                            <ConfirmationLinksForm article={order.resource} order_id={order.id} />
                          </Card>
                        </TabPane>
                      }
                      {(order.resource.state === 'posted' || order.resource.state === 'boosted' || order.resource.state === 'completed') &&
                        <TabPane tab="Promotion information" key="promotion_info">
                          <Card>
                            <PromotionInfo article={order.resource} order_id={order.id} />
                          </Card>
                        </TabPane>
                      }
                      {(order.resource.state === 'boosted' || order.resource.state === 'completed') &&
                        <TabPane tab="Campaign performance" key="campaign_performance">
                          <Card>
                            <CampaignPerformance article={order.resource} order_id={order.id} />
                          </Card>
                        </TabPane>
                      }
                    </>
                  }

                  <TabPane tab="Conversation" key="conversation">
                    {
                      this.showConvertKeywordButton() && 
                      <>
                        <div style={{ textAlign: 'right' }}>
                          <Button
                            loading={loading}
                            type="primary"
                            onClick={this.toggleConfirmationModal}
                          >
                            Convert Google KWs
                          </Button>
                        </div>
                      </>
                    }
                    <Card>
                      <Messages container={order} mobileView={mobileView} container_type='VendorOrder' />
                    </Card>
                  </TabPane>
                </Tabs>
              </Col>
            }
          </Row>
        </>
      )
    }
  }
}

const mapStateToProps = state => ({
  order: state.orders.order,
  loading: state.orders.loading,
  error: state.orders.error,
  success: state.prArticle.success,
});

export default connect(mapStateToProps)(OrderDetails);
