import { authHeader } from '../util/api';
import _ from 'lodash';
import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_PROFILE_BEGIN = 'FETCH_PROFILE_BEGIN';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const UPDATE_PROFILE_BEGIN = 'UPDATE_PROFILE_BEGIN';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';
export const UPDATE_PROFILE_CLEAR = 'UPDATE_PROFILE_CLEAR';

export const DECREMENT_UNREAD_QUOTE_MESSAGES_COUNT = 'DECREMENT_UNREAD_QUOTE_MESSAGES_COUNT';
export const DECREMENT_UNREAD_ORDER_MESSAGES_COUNT = 'DECREMENT_UNREAD_ORDER_MESSAGES_COUNT';
export const DECREMENT_UNREAD_CONVERSATION_MESSAGES_COUNT = 'DECREMENT_UNREAD_CONVERSATION_MESSAGES_COUNT';

export const INCREMENT_UNREAD_QUOTE_MESSAGES_COUNT = 'INCREMENT_UNREAD_QUOTE_MESSAGES_COUNT';
export const INCREMENT_UNREAD_ORDER_MESSAGES_COUNT = 'INCREMENT_UNREAD_ORDER_MESSAGES_COUNT';
export const INCREMENT_UNREAD_CONVERSATION_MESSAGES_COUNT = 'INCREMENT_UNREAD_CONVERSATION_MESSAGES_COUNT';

export const fetchProfileBegin = () => ({
  type: FETCH_PROFILE_BEGIN
});

export const fetchProfileSuccess = (profile) => ({
  type: FETCH_PROFILE_SUCCESS,
  payload: { profile }
});

export const fetchProfileFailure = error => ({
  type: FETCH_PROFILE_FAILURE,
  payload: { error }
});

export function fetchProfile() {
  return dispatch => {
    dispatch(fetchProfileBegin());
    return axios.get(`${BASE_API_URL}/profile.json`, {
      headers: authHeader()
    })
      .then(json => {
        dispatch(fetchProfileSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(fetchProfileFailure(error)));
  };
}

export const updateProfileBegin = () => ({
  type: UPDATE_PROFILE_BEGIN
});

export const updateProfileSuccess = (profile) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: { profile }
});

export const updateProfileFailure = error => ({
  type: UPDATE_PROFILE_FAILURE,
  payload: { error }
});

export const updateProfileClear = () => ({
  type: UPDATE_PROFILE_CLEAR
});

export function updateProfile(values) {
  return dispatch => {
    dispatch(updateProfileBegin());
    return axios.put(`${BASE_API_URL}/profile.json`,
      JSON.stringify({ profile: createProfileFromValues(values) }),
      { headers: authHeader() })
      .then(json => {
        dispatch(updateProfileSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(updateProfileFailure(error.response.data)));
  };
}

export const decreaseUnreadQuoteMessagesCount = (unreadQuoteMessagesCount) => ({
  type: DECREMENT_UNREAD_QUOTE_MESSAGES_COUNT,
  payload: { unreadQuoteMessagesCount }
}) 

export const decreaseUnreadOrderMessagesCount = (unreadOrderMessagesCount) => ({
  type: DECREMENT_UNREAD_ORDER_MESSAGES_COUNT,
  payload: { unreadOrderMessagesCount }
}) 

export const decreaseUnreadConversationMessagesCount = (unreadConversationMessagesCount) => ({
  type: DECREMENT_UNREAD_CONVERSATION_MESSAGES_COUNT,
  payload: { unreadConversationMessagesCount }
})

export const increaseUnreadQuoteMessagesCount = (unreadQuoteMessagesCount) => ({
  type: INCREMENT_UNREAD_QUOTE_MESSAGES_COUNT,
  payload: { unreadQuoteMessagesCount }
}) 

export const increaseUnreadOrderMessagesCount = (unreadOrderMessagesCount) => ({
  type: INCREMENT_UNREAD_ORDER_MESSAGES_COUNT,
  payload: { unreadOrderMessagesCount }
}) 

export const increaseUnreadConversationMessagesCount = (unreadConversationMessagesCount) => ({
  type: INCREMENT_UNREAD_CONVERSATION_MESSAGES_COUNT,
  payload: { unreadConversationMessagesCount }
})

function createProfileFromValues(values) {
  let result = _.pick(values, ['name', 'current_password', 'password', 'password_confirmation', 'profile_photo']);
  return result;
}