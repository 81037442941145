import React from "react";
import { connect } from "react-redux";
import styled from 'styled-components'

import { Comment, Form, Button, List, Upload, Space } from "antd";
import moment from "moment";
import { createMessage, createMessageClear, fetchMessages } from "../../actions/messageActions";
import { UploadOutlined } from '@ant-design/icons';
import UserAvatar from "./UseAvatar";
import { profilePhotoAuthHeader } from "../../util/api";
import { decreaseUnreadQuoteMessagesCount, decreaseUnreadOrderMessagesCount, decreaseUnreadConversationMessagesCount } from "../../actions/profileActions";
import ErrorDisplay from "./ErrorDisplay";
import RichTextEditor from '../shared/inputs/RichTextEditor';
import parseHtml from '../../util/parseHtml';

const ListWrapper = styled(List)`
  white-space: pre-line;
`;

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const setMargin = (user, author, mobileView) => {
  return !mobileView && user !== author ? '20rem' : 0
}

const CommentList = ({ messages, loading, current_user, mobileView }) => (
  <>
    <ListWrapper
      dataSource={messages}
      loading={loading}
      header={`${messages.length} ${messages.length > 1 ? "messages" : "message"}`}
      itemLayout="horizontal"
      renderItem={item => (
        <li style={{
          marginLeft: setMargin(current_user.id, item.author.id, mobileView)
        }}>
          <Comment
            author={item.author.name}
            avatar={<UserAvatar user={item.author} size={30} />}
            content={
              <Space direction='vertical'>
                <p>{parseHtml(item.message)}</p>
                {item.attachments && item.attachments.map((attachment, index) => (
                  <a key={index} href={attachment.url} target="_blank" rel="noopener noreferrer">
                    {attachment.name}
                  </a>
                ))}
              </Space>
            }
            datetime={moment(item.created_at).fromNow()}
          />
        </li>
      )}
    />
  </>
);

const normFile = e => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList || [];
};

const Editor = ({ kind, loading, onFinish, error }) => {
  const [form] = Form.useForm();
  const rules = [];
  const maxFileSizeBytes = 20 * 1024 * 1024;
  const maxTotalFileSize = 100 * 1024 * 1024;
  const maxFiles = 15;

  rules.push({
    validator: (_, fileList) => {
      if (fileList && fileList.length) {
        const totalSize = fileList.reduce((acc, file) => acc + file.size, 0);
        const invalidFile = fileList.find(file => file.size > maxFileSizeBytes);
        if (invalidFile) {
          return Promise.reject("Each file must be less than 20 MB");
        }

        if (totalSize > maxTotalFileSize) {
          return Promise.reject("Total size of uploaded files must be less than 100 MB");
        }

        if (fileList.length > maxFiles) {
          return Promise.reject(`Maximum ${maxFiles} files can be uploaded`);
        }

        return Promise.resolve();
      } else {
        return Promise.resolve();
      }
    },
  });

  const onSubmit = (values) => {
    onFinish(values);
    form.resetFields();
  }

  return (
    <Form form={form} onFinish={onSubmit}>
      <ErrorDisplay error={error} />
      <Form.Item name='message'>
        <RichTextEditor style={{ height: '240px', 'margin-bottom': '40px' }} />
      </Form.Item>
      <Form.Item
        label="Add Attachments"
        name="attachment"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra="pdf / doc / docx format files only"
        rules={rules}
      >
        <Upload name="file"
          data={{ kind }}
          headers={profilePhotoAuthHeader()}
          action={`${BASE_API_URL}/file_uploads`}
          multiple={true}
          listType="picture">
          <Button>
            <UploadOutlined /> Click to upload
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          loading={loading}
          type="primary"
        >
          Send
      </Button>
      </Form.Item>
    </Form>
  )
};

class Messages extends React.Component {

  componentDidMount() {
    const { container, container_type } = this.props
    this.props.dispatch(fetchMessages(container_type, container.id));
    this.decreaseUnreadCount();
  }

  componentDidUpdate(prevProps, _prevState) {
    if (this.props.createMessageSuccess) {
      this.props.dispatch(createMessageClear());
      this.props.dispatch(fetchMessages(this.props.container_type, this.props.container.id));
    }
  }

  decreaseUnreadCount = () => {
    const { container, container_type, dispatch, unreadQuoteMessages, unreadOrderMessages, unreadConversationMessages } = this.props;

    if (!container.unread) return;

    if (container_type === 'VendorQuote') {
      dispatch(decreaseUnreadQuoteMessagesCount(unreadQuoteMessages));
      dispatch(decreaseUnreadConversationMessagesCount(unreadConversationMessages));
    }
    else if (container_type === 'VendorOrder') {
      dispatch(decreaseUnreadOrderMessagesCount(unreadOrderMessages));
      dispatch(decreaseUnreadConversationMessagesCount(unreadConversationMessages));
    }
  };

  handleSubmit = (values) => {
    const { container, container_type, dispatch } = this.props;
    dispatch(createMessage(container_type, container.id, values));
  };

  render() {
    const { messages, profile, mobileView, createMessageLoading, loading, createMessageError } = this.props;

    return (
      <>
        {messages.length > 0 && <CommentList loading={loading} messages={messages} current_user={profile} mobileView={mobileView} />}
        <Comment
          avatar={
            <UserAvatar user={profile} size={30} />
          }
          content={
            <Editor
              onFinish={this.handleSubmit}
              loading={createMessageLoading}
              error={createMessageError}
              kind='message_attachment' />
          }
        />
      </>
    )
  }
}
const mapStateToProps = state => ({
  profile: state.profile.profile,
  messages: state.messages.items,
  createMessageSuccess: state.messages.createMessageSuccess,
  createMessageError: state.messages.createMessageError,
  createMessageLoading: state.messages.createMessageLoading,
  unreadQuoteMessages: state.profile.unreadQuoteMessages,
  unreadOrderMessages: state.profile.unreadOrderMessages,
  unreadConversationMessages: state.profile.unreadConversationMessages,
});

export default connect(mapStateToProps)(Messages);
