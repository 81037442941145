import React from 'react';
import { Form, InputNumber, Modal, Tooltip } from 'antd';
import { ProductsWithETA } from "../../util/productConstants";

const SubmitQuoteModal = ({ visible, onSubmit, onCancel, formRef, selectedQuote }) => {
  return (
    <Modal
      title="Submit Quote"
      visible={visible}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      {selectedQuote && ProductsWithETA.includes(selectedQuote.kind) && (
        <Form ref={formRef}>
          <Form.Item
            name="eta"
            label={
              <Tooltip title="Expected number of business days to complete the work one the quote is paid:">
                <span>ETA (Business Days)</span>
              </Tooltip>
            }
            rules={[
              {
                required: true,
                message: 'Please input the ETA!',
              },
              {
                type: 'number',
                min: 1,
                max: 100,
                message: 'ETA must be a positive number!',
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default SubmitQuoteModal;
