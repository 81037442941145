import { combineReducers } from "redux";
import auth from "./authReducer";
import profile from "./profileReducer";
import quotes from "./quoteReducer"
import messages from "./messageReducer"
import orders from "./orderReducer"
import submissions from "./submissionReducer"
import prArticle from './prArticleReducer'
import inbox from './inboxReducer';

export default combineReducers({
  auth, 
  profile,
  quotes,
  messages,
  orders,
  submissions,
  prArticle,
  inbox
});
