import React from "react";
import { Button, Typography, Space, Divider } from "antd";
import PlatformBlock from "./PlatformBlock";

const { Title, Link } = Typography;

const KeywordBlock = ({ keyword, keywordIndex, keywordBlocks, platformType, addBlock, removeBlock, handleInputChange, changePlatform, isKeywordCombinationValid }) => {
  const keywordBlock = keywordBlocks[keywordIndex] || {};
  const platform = Object.keys(keywordBlock)[0];

  return (
    <div key={keywordIndex} style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", padding: "15px", marginBottom: "20px", position: "relative" }}>
      <Title level={5}>
        Keyword {keywordIndex + 1} - {keyword.keyword_phrase} + {keyword.tag_phrase}
      </Title>
      {platform && (
        <>
          <Title level={5} style={{ display: "inline-block", marginRight: "20px" }}>
            Platform: {platform}
          </Title>
          <Link onClick={() => changePlatform(keywordIndex)}>
            Change to {platform === "Bing" ? "YouTube" : "Bing"}
          </Link>
      </>
      )}
      {Object.keys(keywordBlock).length === 0 && (
        <Space style={{ marginLeft: "20px" }}>
          <Button onClick={() => addBlock(keywordIndex, "YouTube")}>
            Add a block for YouTube
          </Button>
          <Button type="primary" onClick={() => addBlock(keywordIndex, "Bing")}>
            Add a block for Bing
          </Button>
        </Space>
      )}
      {Object.keys(keywordBlock).map((platform) => (
        <PlatformBlock
          key={platform}
          platform={platform}
          blocks={keywordBlock[platform]}
          keywordIndex={keywordIndex}
          handleInputChange={handleInputChange}
          isKeywordCombinationValid={isKeywordCombinationValid}
        />
      ))}
      {platform && (
        <Button type="link" onClick={() => removeBlock(keywordIndex)} style={{ position: "absolute", bottom: "10px", right: "10px" }}>
          Remove Block
        </Button>
      )}
      <Divider />
    </div>
  );
};

export default KeywordBlock;
