import React from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import { connect } from 'react-redux';

import { fetchProfile } from '../actions/profileActions'

import PrivateRoute from './shared/PrivateRoute'

import Login from './Login'
import Dashboard from './Dashboard'
import ScrollToTop from "./ScrollToTop";
import Account from './Account'
import SystemAccess from "./account/SystemAccess";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import QuoteRequests from "./QuoteRequests";
import QuoteDetails from "./quotes/QuoteDetails";
import OrderDetails from "./orders/OrderDetails";
import Orders from './Orders';
import Inbox from './Inbox';

class Routes extends React.Component {

  componentDidMount() {
    const user = localStorage.getItem('user');
    if (user) {
      this.props.dispatch(fetchProfile());
    }
  }

  render() {
    const { profile } = this.props;

    return (
      <Router basename="/">
        <ScrollToTop />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <PrivateRoute exact path="/" component={Dashboard} user={profile} />
          <PrivateRoute exact path="/quotes/:id" component={QuoteDetails} user={profile} />
          <PrivateRoute exact path="/orders/:id" component={OrderDetails} user={profile} />
          <PrivateRoute exact path="/inbox" component={Inbox} user={profile} />
          <PrivateRoute exact path="/orders" component={Orders} user={profile} />
          <PrivateRoute exact path="/quotes" component={QuoteRequests} user={profile} />
          <PrivateRoute exact path="/account" component={Account} user={profile} />
          <PrivateRoute exact path="/account/system_access" component={SystemAccess} user={profile} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
});
export default connect(mapStateToProps)(Routes);
