import React from "react"
import { Link } from 'react-router-dom'
import { Menu } from 'antd';

class AccountMenu extends React.Component {
  render() {
    return (
      <Menu selectedKeys={[this.props.nav]} mode="horizontal" style={{ marginBottom: '2em' }}>
        <Menu.Item key="contact">
          <span>Contact information</span>
          <Link to={'/account'} />
        </Menu.Item>
        <Menu.Item key="access">
          <span>System access</span>
          <Link to={'/account/system_access'} />
        </Menu.Item>
      </Menu>
    );
  }
}

export default AccountMenu;

