import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { fetchMessages, createMessage, createMessageClear, markAsUnread } from '../../actions/messageActions';
import { Comment, Form, Button, List, message, Upload, Space, Tag } from 'antd';
import { ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons';
import UserAvatar from '../shared/UserAvatar';
import {profilePhotoAuthHeader} from '../../util/api';
import RichTextEditor from '../shared/inputs/RichTextEditor';
import parseHtml from '../../util/parseHtml';
import {
  increaseUnreadConversationMessagesCount,
  increaseUnreadOrderMessagesCount,
  increaseUnreadQuoteMessagesCount
} from '../../actions/profileActions';
import { useState } from 'react';

const ListWrapper = styled(List)`
  white-space: pre-line;
`;

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

const CommentList = ({ messages, loading }) => (
  <>
    <ListWrapper
      dataSource={messages}
      loading={loading}
      header={`${messages.length} ${messages.length > 1 ? "messages" : "message"}`}
      itemLayout="horizontal"
      renderItem={item => (
        <li>
          <Comment
            author={
              <Space direction='horizontal'>
                {item.author.name}
                {item.author.vendor_name && <Tag>{item.author.vendor_name}</Tag>}
              </Space>
            }
            avatar={<UserAvatar user={item.author} size={30} />}
            content={
              <Space direction='vertical'>
                <p>{parseHtml(item.message)}</p>
                {item.attachments && item.attachments.map((attachment, index) => (
                  <a key={index} href={attachment.url} target="_blank" rel="noopener noreferrer">
                    {attachment.name}
                  </a>
                ))}
              </Space>
            }
            datetime={moment(item.created_at).fromNow()}
          />
        </li>
      )}
    />
  </>
);

const normFile = e => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList || [];
};

const maxSize = 20 * 1024 * 1024; // 20MB

const Editor = ({ kind, loading, onFinish }) => {
  const [form] = Form.useForm();
  const rules = [];
  const maxFileSizeBytes = 20 * 1024 * 1024;
  const maxTotalFileSize = 100 * 1024 * 1024;
  const maxFiles = 15;

  rules.push({
    validator: (_, fileList) => {
      if (fileList && fileList.length) {
        const totalSize = fileList.reduce((acc, file) => acc + file.size, 0);
        const invalidFile = fileList.find(file => file.size > maxFileSizeBytes);
        if (invalidFile) {
          return Promise.reject("Each file must be less than 20 MB");
        }

        if (totalSize > maxTotalFileSize) {
          return Promise.reject("Total size of uploaded files must be less than 100 MB");
        }

        if (fileList.length > maxFiles) {
          return Promise.reject(`Maximum ${maxFiles} files can be uploaded`);
        }

        return Promise.resolve();
      } else {
        return Promise.resolve();
      }
    },
  });

  const onSubmit = (values) => {
    if (!(values.message || values.attachment?.length)) {
      message.error('Please enter a message');
      return;
    }
    
    onFinish(values);
    form.resetFields();
  }

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item name='message'>
        <RichTextEditor style={{ height: '250px', 'margin-bottom': '40px' }} />
      </Form.Item>
      <Form.Item
        label="Add Attachments"
        name="attachment"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        extra="pdf / doc / docx format files only"
        rules={rules}
      >
        <Upload
          name="file"
          data={{ kind }}
          headers={profilePhotoAuthHeader()}
          action={`${BASE_API_URL}/file_uploads`}
          listType="picture"
          multiple={true}
        >
          <Button>
            <UploadOutlined /> Click to upload
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          loading={loading}
          type="primary"
        >
          Send
      </Button>
      </Form.Item>
    </Form>
  )
};

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markUnreadEnabled: true
    };
  }

  componentDidMount() {
    const { containerId, containerType } = this.props;
    this.props.dispatch(fetchMessages(containerType, containerId));
  }

  componentDidUpdate(prevProps, _prevState) {
    if (this.props.containerId && prevProps.containerId !== this.props.containerId) {
      this.props.dispatch(fetchMessages(this.props.containerType, this.props.containerId));
    }
    if (this.props.createMessageSuccess) {
      message.success('Message sent.')
      this.props.dispatch(createMessageClear());
      this.props.dispatch(fetchMessages(this.props.containerType, this.props.containerId));
    }
  }

  decreaseUnreadCount = () => {
    const { containerType, dispatch, unreadQuoteMessages, unreadOrderMessages, unreadConversationMessages } = this.props;

    if (containerType === 'VendorQuote') {
      dispatch(increaseUnreadQuoteMessagesCount(unreadQuoteMessages));
    }
    else if (containerType === 'VendorOrder') {
      dispatch(increaseUnreadOrderMessagesCount(unreadOrderMessages));
    }
    dispatch(increaseUnreadConversationMessagesCount(unreadConversationMessages));
  };

  onUnread = () => {
    const { containerType, containerId } = this.props;
    this.props.dispatch(markAsUnread(containerType, containerId));
    this.decreaseUnreadCount();
    this.setState({ markUnreadEnabled: false });
  };


  handleSubmit = (values) => {
    const { containerId, containerType } = this.props;
    this.props.dispatch(createMessage(containerType, containerId, values));
  };

  render() {
    const { messages, profile, mobileView, loading } = this.props;

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button type="link" onClick={this.props.onBack} icon={<ArrowLeftOutlined />}>
            Back
          </Button>

          {messages.length > 0 && <Button type="primary" onClick={this.onUnread} disabled={!this.state.markUnreadEnabled}>
            Mark as Unread
          </Button>}
        </div>

        {messages.length > 0 && <CommentList loading={loading} messages={messages} current_user={profile} mobileView={mobileView} />}
        <Comment
          avatar={
            <UserAvatar user={profile} size={30} />
          }
          content={
            <Editor
              onFinish={this.handleSubmit}
              loading={loading}
              kind='message_attachment' />
          }
        />
      </>
    )
  }
}
const mapStateToProps = state => ({
  profile: state.profile.profile,
  messages: state.messages.items,
  loading: state.messages.loading,
  createMessageSuccess: state.messages.createMessageSuccess,
  unreadQuoteMessages: state.profile.unreadQuoteMessages,
  unreadOrderMessages: state.profile.unreadOrderMessages,
  unreadConversationMessages: state.profile.unreadConversationMessages,
});

export default connect(mapStateToProps)(Messages);
