import { debounce } from 'lodash';
import React from 'react';
import { BASE_INBOX_ROUTES } from '../util/routes'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PageHeader from './shared/PageHeader';
import{ fetchInbox } from '../actions/inboxActions';
import List  from './inbox/List';
import queryString from 'query-string';
import styled from 'styled-components';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const TITLE = 'Inbox'
const Wrapper = styled.div`
  @media(max-width: 768px){
    display: block;
    button {
      margin-left: .5em;
      &:first-of-type {
        margin-left: 0;
      }
    }
    span {
      &:first-child {
        margin-bottom: .5em;
      }
    }
  }
`

class Inbox extends React.Component {
  state = {
    showSearchInput: true,
    page: 1,
    pageSize: 10,
    query: '',
  };

  componentDidMount() {
    this.props.dispatch(fetchInbox());
  }

  handleSearch = debounce((query) => {
    const { pageSize } = this.state;
    this.setState({ query, page: 1 });
    this.props.dispatch(fetchInbox(1, pageSize, query));
  }, 300);

  searchInputVisible = (value) => {
    this.setState({
      showSearchInput: value
    })
  }

  handleTableChange = (pagination) => {
    const page = pagination.current;
    const pageSize = pagination.pageSize;
    let { query } = this.state;
    this.props.dispatch(fetchInbox(page, pageSize, query));
    this.setState({page, pageSize});
  };

  render() {
    const { threads, totalItems, loading, location, mobileView } = this.props;
    const { page, showSearchInput } = this.state;
    const parsedContainer = queryString.parse(location.search);

    return (
      <>
        <PageHeader title={TITLE} routes={BASE_INBOX_ROUTES} />
        <div className="content-holder" style={{ background: '#fff' }}>
          <Wrapper className="button-action-bar">
            {
              showSearchInput && <Input
                placeholder="Search by message" onChange={(e) => this.handleSearch(e.target.value)}
                suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
            }
          </Wrapper>
          <List threads={threads} totalThreads={totalItems} onTableChange={this.handleTableChange} mobileView={mobileView} loading={loading} containerId={parsedContainer.containerId} containerType={parsedContainer.containerType} showContainer={parsedContainer.showContainer} searchInputVisible={this.searchInputVisible} page={page} />
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ inbox }) => ({
  threads: inbox.items,
  loading: inbox.loading,
  error: inbox.error,
  totalItems: inbox.totalItems
});

export default connect(mapStateToProps)(withRouter(Inbox));
