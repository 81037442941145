import React from 'react';
import { Modal, Button } from 'antd';

const ConfirmGoogleKeywordConvertModal = ({ visible, onCancel, resource, onProceed }) => {
  const { keyword_blocks } = resource;
  const numberOfGoogleKeywords = keyword_blocks['google']?.length || 0;
  const participle = numberOfGoogleKeywords === 1 ? 'is' : 'are'
  const keywordText = numberOfGoogleKeywords === 1 ? 'keyword' : 'keywords'
  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      style={{ width: '50%', minHeight: '300px' }}
      footer={[
        <div style={{ textAlign: 'center' }}>
          <Button key="cancel" onClick={onCancel} >
            Cancel
          </Button>
          <Button key="proceed" type="primary" onClick={onProceed}>
            Proceed
          </Button>
        </div>
      ]}
    >
      <div style={{ textAlign: 'center', fontSize: '16px', padding: '20px' }}>
        There {participle} {numberOfGoogleKeywords} {keywordText} from Google. Each keyword for Google can be converted into 7 keywords in Bing and 3 keywords in Youtube
      </div>
    </Modal>
  );
};

export default ConfirmGoogleKeywordConvertModal;
