import { Button, Descriptions, Space } from "antd";
import React from "react"
import { connect } from "react-redux";
import { updateArticle } from "../../actions/prArticleActions";

class PromotionInfo extends React.Component {

  handleFinish = () => {
    const { article } = this.props;
    this.props.dispatch(updateArticle(article.id, {
      op: 'boost'
    }));
  };

  render() {
    const { article, loading } = this.props;

    return (
      <>
        <Descriptions column={1} size="small" title="Links">
          <Descriptions.Item label="Blog post link to article"><a href={article.blog_link}>{article.blog_link}</a></Descriptions.Item>
          <Descriptions.Item label="Facebook link to post"><a href={article.facebook_link}>{article.facebook_link}</a></Descriptions.Item>
          <Descriptions.Item label="Linkedin link to post"><a href={article.linkedin_link}>{article.linkedin_link}</a></Descriptions.Item>
        </Descriptions>
        <Descriptions column={1} size="small" title="Demographics for boosting">
          <Descriptions.Item label="Age">{article.min_age} years to {article.max_age} years</Descriptions.Item>
          <Descriptions.Item label="Zip Code">{article.zip_code}</Descriptions.Item>
          <Descriptions.Item label="Interests">{article.interests}</Descriptions.Item>
          <Descriptions.Item label="Additional info">{article.additional_information}</Descriptions.Item>
          <Descriptions.Item label="Images">
            <Space direction='horizontal'>
              {article.images.map((image) => <img src={image.url} width='100' height='100' />)}
            </Space>
          </Descriptions.Item>
        </Descriptions>
        {article.state === 'posted' &&
          <Button htmlType="submit" type="primary" loading={loading} onClick={this.handleFinish}>
            Confirm boost
          </Button>
        }
      </>
    )
  }
}

const mapStateToProps = state => ({
  error: state.prArticle.error,
  loading: state.prArticle.loading,
});

export default connect(mapStateToProps)(PromotionInfo);