import React from 'react';
import { Avatar } from 'antd';

export const UserAvatar = ({ user, size = 32 }) => {
  if (user === null) {
    return <></>
  }
  else if (user.profile_photo_url === null){
    return <Avatar size={size}>{user.avatar_initials}</Avatar>
  }
  else {
    return <Avatar src={user.profile_photo_url} size={size}></Avatar>
  }
}

export default UserAvatar
