import React from 'react';
import styled from 'styled-components'
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Badge, Layout, Menu } from 'antd';
import {
  HomeOutlined, CloseOutlined, ControlOutlined, DollarOutlined, ShoppingCartOutlined, MessageOutlined
} from '@ant-design/icons';
import { fetchProfile } from '../../actions/profileActions'
import logo from '../../images/logo_v3.png'
import UserAvatar from '../shared/UseAvatar';

const { Sider } = Layout;

const BadgeWrapper = styled(Badge)`
  margin-left: 15px;
`

class Sidebar extends React.Component {

  componentDidMount() {
    this.props.dispatch(fetchProfile());
  }

  getActivePathname() {
    return window.location.pathname;
  }

  getActiveParentMenu(activeRoute) {
    return [];
  }

  getActiveRoute = () => {
    let activeRoute = `/${this.props.route.split('/')[1]}`;
    if (activeRoute === '/subscriptions') {
      activeRoute = '/contacts';
    }
    if (activeRoute === '/tools') {
      activeRoute = '/marketing';
    }
    if (activeRoute === '/undefined') {
      activeRoute = '/';
    }
    return activeRoute;
  }

  render() {
    const { user, mobileView, open, unreadQuoteMessages, unreadOrderMessages, unreadConversationMessages } = this.props;

    let activeRoute = this.getActiveRoute();

    return (
      <Sider
        theme={'light'}
        breakpoint="md"
        collapsedWidth="0"
        collapsed={mobileView && !open}
        onBreakpoint={broken => {
          this.props.onBreakPoint(broken)
        }}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          zIndex: 3,
        }}>
        {mobileView &&
          <CloseOutlined
            onClick={this.props.closeSidebar}
            style={{ fontSize: '20px', marginTop: '10px', marginLeft: '10px' }}
          />
        }
        <div className="logo">
          <img src={logo} alt="Umbrella" />
        </div>
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          <UserAvatar user={user} size={50} />
          <p style={{ marginTop: '10px' }}>{user && `${user.name}`}</p>
        </div>
        <Menu theme="light"
          defaultSelectedKeys={[this.getActivePathname()]}
          defaultOpenKeys={this.getActiveParentMenu(activeRoute)}
          onSelect={this.props.closeSidebar}
          mode="inline" selectedKeys={[activeRoute]}>
          <Menu.Item key={'/'}>
            <HomeOutlined />
            <span>Dashboard</span>
            <Link to={'/'} />
          </Menu.Item>
          <Menu.Item key={'/inbox'}>
            <MessageOutlined />
            <span>Inbox</span>
            <BadgeWrapper count={unreadConversationMessages} />
            <Link to={'/inbox'} />
          </Menu.Item>
          <Menu.Item key={'/orders'}>
            <ShoppingCartOutlined />
            <span>Orders</span>
            <BadgeWrapper count={unreadOrderMessages} />
            <Link to={'/orders'} />
          </Menu.Item>
          <Menu.Item key={'/quotes'}>
            <DollarOutlined />
            <span>Quotes</span>
            <BadgeWrapper count={unreadQuoteMessages} />
            <Link to={'/quotes'} />
          </Menu.Item>
          <Menu.Item key={'/account'}>
            <ControlOutlined />
            <span>Account settings</span>
            <Link to={'/account'} />
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = state => ({
  user: state.profile.profile,
  unreadQuoteMessages: state.profile.unreadQuoteMessages || 0,
  unreadOrderMessages: state.profile.unreadOrderMessages || 0,
  unreadConversationMessages: state.profile.unreadConversationMessages || 0,
});
export default connect(mapStateToProps)(Sidebar);
