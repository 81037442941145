import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { BASE_QUOTE_REQUEST_ROUTES } from "../util/routes";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PageHeader from "./shared/PageHeader";
import {
  fetchQuotes,
  submitQuote,
  submitQuoteClear,
} from "../actions/quotesActions";
import { Input, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import List from "./quotes/List";

const TITLE = "Quote Requests";

const Wrapper = styled.div`
  @media (max-width: 768px) {
    display: block;
    button {
      margin-left: 0.5em;
      &:first-of-type {
        margin-left: 0;
      }
    }
    span {
      &:first-child {
        margin-bottom: 0.5em;
      }
    }
  }
`;

class QuoteRequests extends React.Component {
  state = {
    page: 1,
    pageSize: 10,
    filters: {},
    sorter: {},
    query: "",
  };

  componentDidMount() {
    this.props.dispatch(fetchQuotes());
  }

  componentDidUpdate(prevProps, _prevState) {
    if (this.props.submitQuoteSuccess) {
      message.success("Quote request has been submitted");
      this.props.dispatch(submitQuoteClear());
      this.props.dispatch(fetchQuotes());
    }
  }

  handleVendorQuoteSubmit = async (id, values = {}) => {
    this.props.dispatch(submitQuote(id, values));
  };

  handleTableChange = (pagination, filters, sorter) => {
    const page = pagination.current;
    const pageSize = pagination.pageSize;
    let { query } = this.state;
    this.props.dispatch(fetchQuotes(page, pageSize, filters, sorter, query));
    this.setState({
      page,
      filters,
      sorter,
      pageSize,
    });
  };

  handleSearch = _.debounce((query) => {
    let { filters, sorter, pageSize } = this.state;
    this.setState({ query, page: 1 });
    this.props.dispatch(fetchQuotes(1, pageSize, filters, sorter, query));
  }, 300);

  render() {
    const { quotes, totalItems, loading } = this.props;
    const { page } = this.state;

    return (
      <>
        <PageHeader title={TITLE} routes={BASE_QUOTE_REQUEST_ROUTES} />
        <div className="content-holder" style={{ background: "#fff" }}>
          <Wrapper className="button-action-bar">
            <Input
              placeholder="Search by member or quote ID"
              onChange={(e) => this.handleSearch(e.target.value)}
              suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            />
          </Wrapper>
          <List
            quotes={quotes}
            onQuoteSubmit={this.handleVendorQuoteSubmit}
            totalQuotes={totalItems}
            onTableChange={this.handleTableChange}
            loading={loading}
            page={page}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  quotes: state.quotes.items,
  totalItems: state.quotes.totalItems,
  loading: state.quotes.loading,
  error: state.quotes.error,
  submitQuoteSuccess: state.quotes.submitQuoteSuccess,
});

export default connect(mapStateToProps)(withRouter(QuoteRequests));
