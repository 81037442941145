import React from "react"
import { connect } from "react-redux";
import { fetchProfile, updateProfile, updateProfileClear } from "../../../actions/profileActions";
import PhotoForm from "../PhotoForm";
import { Skeleton, message } from "antd";

class ProfilePhotoForm extends React.Component {

  componentDidMount() {
    this.props.dispatch(fetchProfile());
  }

  onSubmit = values => {
    this.props.dispatch(updateProfile(values));
  }

  render() {
    const { profile, success } = this.props;
    const loading = (profile == null || profile === undefined);

    if (loading) {
      return (
        <Skeleton />
      )
    }
    else {
      return (
        <PhotoForm
          imageUrl={profile.profile_photo_url}
          onSubmit={this.onSubmit}
          imageLabel="Profile Photo"
          kind="profile_photo" />
      )
    }
  }
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
  loading: state.profile.loading,
  error: state.profile.error,
  success: state.profile.success,
});

export default connect(mapStateToProps)(ProfilePhotoForm);