import React from "react";
import { Descriptions, Typography } from "antd";
const { Title } = Typography;

const AnswerBotPublishAgent = ({ resource }) => {
  return (
    <>
      <Title level={4}>Order Information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Purpose">New Bot published, configure the email summary action</Descriptions.Item>
        <Descriptions.Item label="Agent Name">{resource.agent_name}</Descriptions.Item>
        <Descriptions.Item label="Agent ID">{resource.agent_id}</Descriptions.Item>
        <Descriptions.Item label="Agent UUID">{resource.identifier}</Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default AnswerBotPublishAgent;
