import { authHeader, buildSortString, buildFilterString } from "../util/api";
import _ from "lodash";
import axios from "axios";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const FETCH_ORDERS_BEGIN = "FETCH_ORDERS_BEGIN";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE";

export const FETCH_ORDER_BEGIN = "FETCH_ORDER_BEGIN";
export const FETCH_ORDER_SUCCESS = "FETCH_ORDER_SUCCESS";
export const FETCH_ORDER_FAILURE = "FETCH_ORDER_FAILURE";
export const FETCH_ORDER_CLEAR = "FETCH_ORDER_CLEAR";

export const COMLPETE_ORDER_BEGIN = 'COMLPETE_ORDER_BEGIN';
export const COMLPETE_ORDER_SUCCESS = 'COMLPETE_ORDER_SUCCESS';
export const COMLPETE_ORDER_FAILURE = 'COMLPETE_ORDER_FAILURE';
export const COMLPETE_ORDER_CLEAR = 'COMLPETE_ORDER_CLEAR';

export const CONVERT_KEYWORDS_BEGIN = 'CONVERT_KEYWORDS_BEGIN';
export const CONVERT_KEYWORDS_SUCCESS = 'CONVERT_KEYWORDS_SUCCESS';
export const CONVERT_KEYWORDS_FAILURE = 'CONVERT_KEYWORDS_FAILURE';
export const CONVERT_KEYWORDS_CLEAR = 'CONVERT_KEYWORDS_CLEAR';

export const markOrderAsCompleteBegin = () => ({
  type: COMLPETE_ORDER_BEGIN
});

export const markOrderAsCompleteSuccess = (order) => ({
  type: COMLPETE_ORDER_SUCCESS,
  payload: { order }
});

export const markOrderAsCompleteFailure = error => ({
  type: COMLPETE_ORDER_FAILURE,
  payload: { error }
});

export const markOrderAsCompleteClear = () => ({
  type: COMLPETE_ORDER_CLEAR
});


export const convertKeywordsBegin = () => ({
  type: CONVERT_KEYWORDS_BEGIN
});

export const convertKeywordsSuccess = (order) => ({
  type: CONVERT_KEYWORDS_SUCCESS
});

export const convertKeywordsFailure = error => ({
  type: CONVERT_KEYWORDS_FAILURE,
  payload: { error }
});

export const convertKeywordsClear = () => ({
  type: CONVERT_KEYWORDS_CLEAR
});

export const fetchOrdersBegin = () => ({
  type: FETCH_ORDERS_BEGIN,
});

export const fetchOrdersSuccess = (orders, totalItems) => ({
  type: FETCH_ORDERS_SUCCESS,
  payload: { orders, totalItems },
});

export const fetchOrdersFailure = (error) => ({
  type: FETCH_ORDERS_FAILURE,
  payload: { error },
});

export function fetchOrders(
  page = 1,
  pageSize = 25,
  filters,
  sorter,
  query = ""
) {
  return (dispatch) => {
    dispatch(fetchOrdersBegin());
    let sortString = buildSortString(sorter);
    let filterString = buildFilterString(filters);
    const queryString = query
      ? `&q[order_number_or_contact_first_name_or_contact_last_name_or_contact_user_first_name_or_contact_user_last_name_cont]=${query}`
      : "";

    return axios
      .get(
        `${BASE_API_URL}/vendor_orders.json?page=${page}&per_page=${pageSize}${sortString}${filterString}${queryString}`,
        {
          headers: authHeader(),
        }
      )
      .then((json) => {
        dispatch(
          fetchOrdersSuccess(json.data.vendor_orders, json.data.total_entries)
        );
        return json.data.vendor_orders;
      })
      .catch((error) => dispatch(fetchOrdersFailure(error)));
  };
}

export const fetchOrderBegin = () => ({
  type: FETCH_ORDER_BEGIN,
});

export const fetchOrderSuccess = (order) => ({
  type: FETCH_ORDER_SUCCESS,
  payload: { order },
});

export const fetchOrderFailure = (error) => ({
  type: FETCH_ORDER_FAILURE,
  payload: { error },
});

export function fetchOrder(id) {
  return (dispatch) => {
    dispatch(fetchOrderBegin());
    return axios
      .get(`${BASE_API_URL}/vendor_orders/${id}.json`, {
        headers: authHeader(),
      })
      .then((json) => {
        dispatch(fetchOrderSuccess(json.data));
        return json.data;
      })
      .catch((error) => dispatch(fetchOrderFailure(error)));
  };
}

export function markAsComplete(id) {
  return (dispatch) => {
    dispatch(markOrderAsCompleteBegin());
    return axios
      .post(`${BASE_API_URL}/vendor_orders/${id}/complete.json`, 
        { },
        { headers: authHeader() })
      .then((json) => {
        dispatch(markOrderAsCompleteSuccess(json.data));
        return json.data;
      })
      .catch((error) => dispatch(markOrderAsCompleteFailure(error)));
  };
}

export function convertKeywords(id, data, conversionStatus) {
  return (dispatch) => {
    dispatch(convertKeywordsBegin());
    return axios
      .post(`${BASE_API_URL}/vendor_orders/${id}/keywords_conversions.json`, 
      { vendor_order: data, keywords_converted: conversionStatus },
        { headers: authHeader() })
      .then((json) => {
        dispatch(convertKeywordsSuccess(json.data));
        return json.data;
      })
      .catch((error) => dispatch(convertKeywordsFailure(error)));
  };
}

export const fetchOrderClear = () => ({
  type: FETCH_ORDER_CLEAR,
});
