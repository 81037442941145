import _ from "lodash";
import React from "react";
import { Descriptions, Typography } from "antd";
const { Title } = Typography;

const SaatchiAccount = ({ resource }) => {
  return (
    <>
      <Title level={4}>Order Information</Title>
      <Descriptions column={1} layout="vertical" size="small">
      <Descriptions.Item label="Package">
          {resource.package_name}
        </Descriptions.Item>
        <Descriptions.Item label="Website URL">
          {resource.website_url}
        </Descriptions.Item>
        <Descriptions.Item label="Business owner email">
          {resource.email}
        </Descriptions.Item>
        <Descriptions.Item label="Business owner name">
          {resource.owner_name}
        </Descriptions.Item>
        <Descriptions.Item label="Business name">
          {resource.name}
        </Descriptions.Item>
        <Descriptions.Item label="Desired content/Saatchi language">
          {resource.language}
        </Descriptions.Item>
        <Descriptions.Item label="Do you have a Wordpress site?">
          {resource.wordpress_site}
        </Descriptions.Item>
        { resource.wordpress_site && 
          <>
            <Descriptions.Item label="Wordpress Admin URL">
              {resource.wordpress_admin_url}
            </Descriptions.Item>
            <Descriptions.Item label="Wordpress username">
              {resource.wordpress_username}
            </Descriptions.Item>
            <Descriptions.Item label="Wordpress password">
              {resource.wordpress_password}
            </Descriptions.Item>
          </>
        }
        <Descriptions.Item label="Notification emails">
          {resource.temporary_notification_emails}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default SaatchiAccount;
