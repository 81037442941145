import React from "react"
import { connect } from "react-redux";
import { Helmet } from 'react-helmet'
import { Skeleton } from "antd";

class Dashboard extends React.Component {

  render() {
    const { dashboard } = this.props;

    if (!dashboard || dashboard === {}) {
      return <Skeleton />
    }

    return (
      <>
        <Helmet><title>Dashboard</title></Helmet>
        <div className="content-holder" style={{ background: '#fff' }}>
          The dashboard goes here
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(Dashboard);

