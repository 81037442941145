import React from "react";
import { Descriptions, Typography } from "antd";
const { Title } = Typography;

const AnswerBotAgentSetup = ({ resource }) => {
  return (
    <>
      <Title level={4}>Order Information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Product Subscription ID ">{resource.purchase_id}</Descriptions.Item>
        <Descriptions.Item label="Account Email">{resource.account_email}</Descriptions.Item>
        <Descriptions.Item label="Agent Name">{resource.agent_name}</Descriptions.Item>
        <Descriptions.Item label="Agent Description">{resource.agent_description}</Descriptions.Item>
        <Descriptions.Item label="Notes">{resource.notes}</Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default AnswerBotAgentSetup;
