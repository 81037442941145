import React from "react"
import { connect } from "react-redux";

import { Skeleton, message, Form, Button } from 'antd';
import ErrorDisplay from '../../components/shared/ErrorDisplay';
import { fetchProfile, updateProfileClear, updateProfile } from "../../actions/profileActions";
import InputField from '../shared/inputs/InputField';

class ContactForm extends React.Component {

  componentDidMount() {
    this.props.dispatch(fetchProfile());
  }

  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.success !== this.props.success) {
      if (this.props.success) {
        message.success('Profile updated successfully');
        this.props.dispatch(updateProfileClear());
      }
    }
  }

  onFinish = values => {
    const { profile } = this.props;
    if (profile.address && profile.address.id !== undefined) {
      values.address_id = profile.address.id;
    }
    this.props.dispatch(updateProfile(values));
  };

  render() {
    const { profile, error, success, loading } = this.props;

    if (profile === null || profile === undefined) {
      return (
        <Skeleton />
      )
    }
    else {
      const initialValues = Object.assign(profile, profile.address);
      return (
        <Form layout="vertical" onFinish={this.onFinish} initialValues={initialValues}>
          <ErrorDisplay error={error} />
          <InputField
            label="Name"
            attribute="name"
          />

          <Button htmlType="submit" type="primary">
            Save
          </Button>
        </Form>
      );
    }
  }
}

const mapStateToProps = state => ({
  profile: state.profile.profile,
  loading: state.profile.loading,
  error: state.profile.error,
  success: state.profile.success
});

export default connect(mapStateToProps)(ContactForm);