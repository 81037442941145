export const quoteStatusColorMappings = {
  'draft': 'gray',
  'requested': 'blue',
  'submitted': 'green',
  'cancelled': 'red',
  'paid': 'green',
  'open': 'blue',
  'complete': 'green',
  'active': 'green',
  'revision_requested': 'blue',
}