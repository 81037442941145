import { Button, Form, message, Upload, List, Tag } from "antd";
import React from "react"
import { connect } from "react-redux";
import { createSubmission, createSubmissionClear } from "../../actions/submissionActions";
import ErrorDisplay from "../shared/ErrorDisplay";
import { profilePhotoAuthHeader } from "../../util/api";
import { UploadOutlined } from "@ant-design/icons";
import { fetchOrder } from "../../actions/ordersActions";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

class SubmissionForm extends React.Component {

  handleFinish = values => {
    const { article } = this.props;
    values['pr_article_id'] = article.id
    this.props.dispatch(createSubmission(values));
  };

  componentDidUpdate(prevProps, _prevState) {
    const { order_id } = this.props;

    if (prevProps.success !== this.props.success && this.props.success) {
      this.props.dispatch(createSubmissionClear());
      message.success(`Submission uploaded successfully`);
      this.props.dispatch(fetchOrder(order_id));
    }
  }

  normFile = e => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length > 1) {
      return [e.fileList[1]];
    }
    return e && e.fileList;
  };

  render() {
    const { article, error, loading } = this.props;

    return (
      <>
        {article.submissions.length > 0 &&
          <List
            loading={loading}
            itemLayout="horizontal"
            dataSource={article.submissions}
            renderItem={item => (
              <List.Item>
                <a href={item.url} target='_blank'>{item.name}</a>
                {item.state === 'pending' &&
                  <Tag color='blue' key={item.state}>
                    {item.state.toUpperCase()}
                  </Tag>
                }
                {item.state !== 'pending' &&
                  <Tag color={item.state === 'rejected' ? 'red' : 'green'} key={item.state}>
                    {item.state.toUpperCase()} ON {item[`${item.state}_at`]}
                  </Tag>
                }
              </List.Item>
            )}
          />
        }
        {(article.state === 'pending' || article.state === 'revision_requested') &&
          <Form layout="vertical" onFinish={this.handleFinish} initialValues={article} name="dynamic_form_nest_item" autoComplete="off">
            <ErrorDisplay error={error} />
            <Form.Item
              label="Add Attachment"
              name="file"
              valuePropName="fileList"
              getValueFromEvent={this.normFile}
            >
              <Upload name="file"
                accept='application/pdf, application/msword, .docx'
                headers={profilePhotoAuthHeader()}
                action={`${BASE_API_URL}/file_uploads`} listType="picture">
                <Button>
                  <UploadOutlined /> Click to upload
                </Button>
              </Upload>
            </Form.Item>

            <Button htmlType="submit" type="primary" loading={loading}>
              Submit
            </Button>
          </Form>
        }
      </>
    )
  }
}

const mapStateToProps = state => ({
  error: state.submissions.error,
  success: state.submissions.success,
  loading: state.submissions.loading,
});

export default connect(mapStateToProps)(SubmissionForm);