import { Button, Col, Input, Row, Form, message, InputNumber } from "antd";
import React from "react"
import { connect } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { updateQuote, updateQuoteClear } from "../../actions/quotesActions";
import ErrorDisplay from "../shared/ErrorDisplay";

class EditQuote extends React.Component {

  handleFinish = values => {
    const { quote } = this.props;
    this.props.dispatch(updateQuote(quote.id, values));
  };

  componentDidUpdate(prevProps, _prevState) {
    if (prevProps.success !== this.props.success && this.props.success) {
      this.props.dispatch(updateQuoteClear());
      message.success(`${this.props.quote.number} updated successfully`);
    }
  }

  render() {
    const { quote, error, loading } = this.props;

    const readOnly = quote.state === 'paid';
    const quoteAmounts = quote.quote_amounts

    return (
      <>
        <Form layout="vertical" onFinish={this.handleFinish} initialValues={quote} name="dynamic_form_nest_item" autoComplete="off">
          <ErrorDisplay error={error} />
          <Form.List name="quote_amounts" label="Line items" >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    noStyle
                    shouldUpdate
                  >
                    {() => (
                      <>
                        <Row gutter={16}>
                          <Col span={11}>
                            <Form.Item
                              label="Label"
                              name={[index, "label"]}
                              rules={[{ required: true, message: 'Please enter a label' }]}
                              fieldKey={[field.name, "label"]}
                            >

                              <Input type="text"
                                disabled={readOnly}
                              />

                            </Form.Item>
                          </Col>

                          <Col span={11}>
                            <Form.Item
                              label="Amount"
                              name={[field.name, "amount"]}
                              rules={[{ required: true, message: 'Please enter an amount' }]}
                              fieldKey={[field.fieldKey, "amount"]}
                            >
                              <InputNumber
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                style={{ width: 200 }}
                                disabled={readOnly}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={2} style={{ marginTop: '2.2rem' }}>
                            {!readOnly &&
                              (quoteAmounts === null || quoteAmounts[index] === undefined || quoteAmounts[index]["kind"] === 'custom')
                              && <MinusCircleOutlined onClick={() => remove(field.name)} />
                            }
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form.Item>

                ))}
                {!readOnly &&
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add({ kind: 'custom' })}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add another line item
                  </Button>
                  </Form.Item>
                }
              </>
            )}
          </Form.List>

          <Form.Item name="notes" label="Notes" >
            <Input.TextArea
              rows={5}
              disabled={quote.state === 'paid' ? true : false}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary" loading={loading} disabled={quote.state === 'paid' ? true : false}>
            Save
        </Button>
        </Form>
      </>
    )
  }
}

const mapStateToProps = state => ({
  error: state.quotes.error,
  success: state.quotes.success,
  loading: state.quotes.loading,
});

export default connect(mapStateToProps)(EditQuote);