import _ from 'lodash';
import axios from 'axios';
import { authHeader } from '../util/api';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const UPDATE_ARTICLE_BEGIN = 'UPDATE_ARTICLE_BEGIN';
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS';
export const UPDATE_ARTICLE_FAILURE = 'UPDATE_ARTICLE_FAILURE';
export const UPDATE_ARTICLE_CLEAR = 'UPDATE_ARTICLE_CLEAR';

export const updateArticleBegin = () => ({
  type: UPDATE_ARTICLE_BEGIN
});

export const updateArticleSuccess = (article) => ({
  type: UPDATE_ARTICLE_SUCCESS,
  payload: { article }
});

export const updateArticleFailure = error => ({
  type: UPDATE_ARTICLE_FAILURE,
  payload: { error }
});

export const updateArticleClear = () => ({
  type: UPDATE_ARTICLE_CLEAR
});

export function updateArticle(id, data) {
  return dispatch => {
    dispatch(updateArticleBegin());
    return axios.put(`${BASE_API_URL}/pr_articles/${id}.json`,
      { pr_article: data },
      { headers: authHeader() }
    )
      .then(json => {
        dispatch(updateArticleSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(updateArticleFailure(error.response.data)));
  };
}

