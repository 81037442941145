import { authHeader } from '../util/api';
import _ from 'lodash';
import axios from 'axios';

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const CREATE_SUBMISSION_BEGIN = 'CREATE_SUBMISSION_BEGIN';
export const CREATE_SUBMISSION_SUCCESS = 'CREATE_SUBMISSION_SUCCESS';
export const CREATE_SUBMISSION_FAILURE = 'CREATE_SUBMISSION_FAILURE';
export const CREATE_SUBMISSION_CLEAR = 'CREATE_SUBMISSION_CLEAR';

export const createSubmissionBegin = () => ({
  type: CREATE_SUBMISSION_BEGIN
});

export const createSubmissionSuccess = (submission) => ({
  type: CREATE_SUBMISSION_SUCCESS,
  payload: { submission }
});

export const createSubmissionFailure = error => ({
  type: CREATE_SUBMISSION_FAILURE,
  payload: { error }
});

export const createSubmissionClear = () => ({
  type: CREATE_SUBMISSION_CLEAR
});

export function createSubmission(value) {
  return dispatch => {
    dispatch(createSubmissionBegin());
    return axios.post(`${BASE_API_URL}/submissions.json`,
      JSON.stringify({ submission: value }),
      { headers: authHeader() })
      .then(json => {
        dispatch(createSubmissionSuccess(json.data));
        return json.data;
      })
      .catch(error => dispatch(createSubmissionFailure(error.response.data)));
  };
}
