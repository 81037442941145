import React from "react";
import { Descriptions, Typography } from "antd";
const { Title } = Typography;

const GbpOptimization = ({ resource }) => {
  return (
    <>
      <Title level={4}>Order Information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Product Subscription ID ">{resource.purchase_id}</Descriptions.Item>
        <Descriptions.Item label="Full business name">{resource.business_name}</Descriptions.Item>
        <Descriptions.Item label="Full business address">{resource.business_address}</Descriptions.Item>
        <Descriptions.Item label="Keywords to optimize">{resource.keywords_to_optimize}</Descriptions.Item>
        <Descriptions.Item label="Notes">{resource.notes}</Descriptions.Item>
        <Descriptions.Item label="Business GMB ID (CID)">{resource.business_gmb_cid}</Descriptions.Item>
        <Descriptions.Item label="Do you have a website">{resource.website_url ? "Yes" : "No"}</Descriptions.Item>
        <Descriptions.Item label="Website">{resource.website_url}</Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default GbpOptimization;
