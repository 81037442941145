import React from "react";
import moment from "moment";
import { Descriptions, Typography, Input } from "antd";
const { Title } = Typography;

const WebsiteSetup = ({ resource }) => {
  return (
    <>
      {resource.vendor_quote && (
        <>
          <Title level={4}>Quote Information</Title>
          <Descriptions column={1} layout="vertical" size="small">
            <Descriptions.Item label="Project name">
              {resource.vendor_quote.payload.project_name}
            </Descriptions.Item>
            <Descriptions.Item label="Email address">
              {resource.vendor_quote.payload.email_address}
            </Descriptions.Item>
            <Descriptions.Item label="Business name">
              {resource.vendor_quote.payload.business_name}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {resource.vendor_quote.payload.address}
            </Descriptions.Item>
            <Descriptions.Item label="Platform">
              {resource.vendor_quote.payload.platform}
            </Descriptions.Item>
            <Descriptions.Item label="Number of pages">
              {resource.vendor_quote.payload.number_of_pages}
            </Descriptions.Item>
            <Descriptions.Item label="Take existing images">
              {resource.vendor_quote.payload.take_existing_images}
              {resource.vendor_quote.payload.take_existing_images == 'other' && (
                <> - {resource.vendor_quote.payload.other_existing_image}</>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="User will provide images and logo?">
              {resource.vendor_quote.payload.provide_logo}
              {resource.vendor_quote.payload.provide_logo == 'other' && (
                <> - {resource.vendor_quote.payload.other_provide_logo}</>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Content is to be written by vendor?">
              {resource.vendor_quote.payload.write_content}
              {resource.vendor_quote.payload.write_content == 'other' && (
                <> - {resource.vendor_quote.payload.other_write_content}</>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Website objective">
              {resource.vendor_quote.payload.objective}
            </Descriptions.Item>
            <Descriptions.Item label="Problems to solve">
              {resource.vendor_quote.payload.problems}
            </Descriptions.Item>
            <Descriptions.Item label="Key features">
              {resource.vendor_quote.payload.key_features}
            </Descriptions.Item>
            <Descriptions.Item label="Need third party integrations?">
              {resource.vendor_quote.payload.require_integration}
            </Descriptions.Item>
            <Descriptions.Item label="Estimated go-live date">
              {moment(resource.vendor_quote.go_live_date).format('MM/DD/YYYY')}
            </Descriptions.Item>
            <Descriptions.Item label="Sample websites">
              {resource.vendor_quote.payload.sample_websites}
            </Descriptions.Item>
            {resource.vendor_quote.notes && (
              <Descriptions.Item label="Notes">{resource.vendor_quote.notes}</Descriptions.Item>
            )}

            <Descriptions.Item label="Quote number">
              {resource.vendor_quote.number}
            </Descriptions.Item>
            <Descriptions.Item label="Quote date">
              {moment(resource.vendor_quote.created_at).format("MM/DD/YYYY")}
            </Descriptions.Item>
            {resource.vendor_quote.quote_amounts.map((quote_amount) => (
              <Descriptions.Item label={quote_amount.label}>
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(quote_amount.amount)}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </>
      )}
    </>
  );
};

export default WebsiteSetup;
