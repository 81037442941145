import React from "react"
import { BASE_ACCOUNT_ROUTES } from '../util/routes';
import styled from 'styled-components'

import PageHeader from './shared/PageHeader';

import AccountMenu from './account/AccountMenu';
import ContactForm from './account/ContactForm';
import { Space } from "antd";
import ProfilePhotoForm from "./account/photo_forms/ProfilePhotoForm";

const TITLE = 'Account settings'

const SpaceWrapper = styled.div`
  margin: ${props => props.margin};
`;

const RenderPhotoForms = () => (
  <Space direction="vertical" size="large">
    <ProfilePhotoForm />
  </Space>
)

class Account extends React.Component {
  render() {
    const { mobileView } = this.props;

    return (
      <>
        <PageHeader title={TITLE} routes={BASE_ACCOUNT_ROUTES} />
        <div className="content-holder" style={{ background: '#fff' }}>
          <AccountMenu nav='contact' />
          <div style={{ display: 'flex' }}>
            {!mobileView && <RenderPhotoForms />}
            <ContactForm mobileView={mobileView} />
          </div>
          <SpaceWrapper margin="1rem 0 0 0"/>
          {mobileView &&
            <RenderPhotoForms />
          }
        </div>
      </>
    );
  }
}

export default Account