import {
  FETCH_QUOTES_BEGIN,
  FETCH_QUOTES_SUCCESS,
  FETCH_QUOTES_FAILURE,

  FETCH_QUOTE_BEGIN,
  FETCH_QUOTE_SUCCESS,
  FETCH_QUOTE_FAILURE,
  FETCH_QUOTE_CLEAR,

  UPDATE_QUOTE_BEGIN,
  UPDATE_QUOTE_FAILURE,
  UPDATE_QUOTE_SUCCESS,
  UPDATE_QUOTE_CLEAR,

  SUBMIT_QUOTE_TO_MEMBER_BEGIN,
  SUBMIT_QUOTE_TO_MEMBER_SUCCESS,
  SUBMIT_QUOTE_TO_MEMBER_FAILURE,
  SUBMIT_QUOTE_TO_MEMBER_CLEAR,

  TRANSFER_VENDOR_QUOTE_BEGIN,
  TRANSFER_VENDOR_QUOTE_SUCCESS,
  TRANSFER_VENDOR_QUOTE_FAILURE
} from '../actions/quotesActions';

const initialState = {
  items: [],
  loading: false,
  error: null,
  quote: null,
  success: null
};

export default function quoteReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_QUOTES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_QUOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.quotes,
        totalItems: action.payload.totalItems,
      };
    case FETCH_QUOTES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };

    case FETCH_QUOTE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        quote: action.payload.quote
      };

    case FETCH_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case FETCH_QUOTE_CLEAR:
      return {
        ...state,
        quote: null,
      };

    case UPDATE_QUOTE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case UPDATE_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        query: action.payload.query,
        success: true
      };

    case UPDATE_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_QUOTE_CLEAR:
      return {
        ...state,
        loading: false,
        success: null,
        error: null,
      };

    case SUBMIT_QUOTE_TO_MEMBER_BEGIN:
      return {
        ...state,
        submitQuoteLoading: true,
        submitQuoteError: null
      };

    case SUBMIT_QUOTE_TO_MEMBER_SUCCESS:
      return {
        ...state,
        submitQuoteLoading: false,
        submitQuote: action.payload.quote,
        submitQuoteSuccess: true,
      };

    case SUBMIT_QUOTE_TO_MEMBER_FAILURE:
      return {
        ...state,
        submitQuoteLoading: false,
        submitQuoteError: action.payload.error,
      };

    case SUBMIT_QUOTE_TO_MEMBER_CLEAR:
      return {
        ...state,
        submitQuoteLoading: false,
        submitQuote: null,
        submitQuoteError: null,
        submitQuoteSuccess: null,
      };

    case TRANSFER_VENDOR_QUOTE_BEGIN:
      return {
        ...state,
        vendorQuoteTransferLoading: true,
        error: null
      };

    case TRANSFER_VENDOR_QUOTE_SUCCESS:
      return {
        ...state,
        vendorQuoteTransferLoading: false,
      };

    case TRANSFER_VENDOR_QUOTE_FAILURE:
      return {
        ...state,
        vendorQuoteTransferLoading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
