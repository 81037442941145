import React from 'react';
import { Tag } from 'antd';
import { quoteStatusColorMappings } from '../../util/display';



export const StatusTag = ({ status, mapping = quoteStatusColorMappings }) => (
  <Tag color={mapping[status]} key={status}>
    {status.replace('_', ' ').toUpperCase()}
  </Tag>
)

export default StatusTag