import {
  FETCH_ORDERS_BEGIN,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAILURE,

  FETCH_ORDER_BEGIN,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_FAILURE,
  FETCH_ORDER_CLEAR,

  COMLPETE_ORDER_BEGIN,
  COMLPETE_ORDER_SUCCESS,
  COMLPETE_ORDER_FAILURE,
  COMLPETE_ORDER_CLEAR,

  CONVERT_KEYWORDS_BEGIN,
  CONVERT_KEYWORDS_SUCCESS,
  CONVERT_KEYWORDS_FAILURE,
  CONVERT_KEYWORDS_CLEAR,
} from '../actions/ordersActions';

const initialState = {
  items: [],
  loading: false,
  error: null,
  order: null,
  success: null
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ORDERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.orders,
        totalItems: action.payload.totalItems,
      };
    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };
    case FETCH_ORDER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        order: action.payload.order
      };

    case FETCH_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case FETCH_ORDER_CLEAR:
      return {
        ...state,
        order: null,
      };

    case COMLPETE_ORDER_BEGIN:
      return {
        ...state,
        markOrderAsCompleteLoading: true,
        markOrderAsCompleteError: null
      };

    case COMLPETE_ORDER_SUCCESS:
      return {
        ...state,
        markOrderAsCompleteLoading: false,
        markOrderAsComplete: action.payload.order,
        markOrderAsCompleteSuccess: true,
      };

    case COMLPETE_ORDER_FAILURE:
      return {
        ...state,
        markOrderAsCompleteLoading: false,
        markOrderAsCompleteError: action.payload.error,
      };

    case COMLPETE_ORDER_CLEAR:
      return {
        ...state,
        markOrderAsCompleteLoading: false,
        markOrderAsComplete: null,
        markOrderAsCompleteError: null,
        markOrderAsCompleteSuccess: null,
      };

    case CONVERT_KEYWORDS_BEGIN:
      return {
        ...state,
        loaging: true,
        error: null
      };

    case CONVERT_KEYWORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        convertKeywordSuccess: true,
      };

    case CONVERT_KEYWORDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case CONVERT_KEYWORDS_CLEAR:
      return {
        ...state,
        loading: false,
        error: null,
        convertKeywordSuccess: null,
      };

    default:
      return state;
  }
}
