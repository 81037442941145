import React from "react";
import { Redirect, Link } from 'react-router-dom';
import { Form, Input, Button, Card, Alert, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { connect } from 'react-redux';
import { login } from "../actions/userActions";

import logo from '../images/logo_large.png'
import { updateImpersonateUserToken } from '../util/impersonate'

class Login extends React.Component {

  state = {
    redirectToReferrer: false
  }

  handleSubmit = values => {
    const { username, password } = values;
    this.props.dispatch(login(username, password));
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const redirectToReferrer = (this.props.user !== undefined && this.props.user !== null && this.props.user.id !== undefined)
    const { error, loading } = this.props;

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const impersonateToken = params.get('impersonate_token');

    if (impersonateToken) {
      updateImpersonateUserToken(impersonateToken)
      return <Redirect to={from} />
    }

    if (redirectToReferrer === true) {
      return <Redirect to={from} />
    }

    return (
      <div className="login-container">
        <Card style={{ width: 300 }}>
          {error ? <Alert message="Invalid email or password" type="error" style={{ marginBottom: '1em' }} /> : ''}
          <Form layout='vertical' onFinish={this.handleSubmit}>
            <img src={logo} style={{ marginBottom: '2em' }} alt="Umbrella" />
            <Form.Item name="username" rules={[{ required: true, message: 'Please enter your username' }]}>
              <Input
                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Please enter your password' }]}>
              <Input
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Password"
                type="password"
              />
            </Form.Item>
            <Button block type="primary" htmlType="submit" className="login-form-button" disabled={loading}>
              Log in
            </Button>
            {loading ? <div style={{ textAlign: 'center' }}><Spin /></div> : ''}
            <div style={{marginTop: '1rem'}}></div>
            <div>
              <Link style={{ float: 'right' }} to="/forgot-password">
                Forgot password
              </Link>
            </div>
          </Form>
        </Card>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.auth.user,
  loading: state.auth.loading,
  error: state.auth.error
});
export default connect(mapStateToProps)(Login);